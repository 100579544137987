<template>
      <div class="col col-12 col-lg-9 mx-auto pl-0 pr-0">
        <div class="col col-12">
          <h3 class="mb-4">{{ appName }}</h3>
        </div>

        <div class="card">
          <template v-if="allDone">
            <h4 class="mt-5 pl-3 pr-3">Thank you for completing the survey, entry will be processed and sent to your email address shortly.</h4>

            <div class="mt-5 mb-5 text-center">
                  <button @click="restartSubmission" class="btn btn-secondary">Restart</button>
            </div>
          </template>
          <template v-else-if="!hasError && isSaved && formId">
            <h4 class="mt-3 pl-3 pr-3">Your progress has been saved,  please use the code <span class="text-danger">{{ formId }}</span> to re-access and finalize the submission of this form.</h4>

            <div class="mt-4 mr-4 ml-3 alert alert-info info-block">
                <span class="bold">Note:</span> You should save the code above somewhere safe as you will need it to complete this form later.
            </div>
                
            <div class="mt-2 mb-4 text-center">
                  <button @click="cancelContinue" class="btn btn-secondary">Continue</button>
            </div>
          </template>
          <template v-else>
            <template v-if="hasError">
              <div :class="{ 'card-body workarea': true, 'page-error': step > 0, 'input-error': true }">
                {{ formMessage }}

                <div class="alert alert-info mt-3 info-block" v-if="hasPermissionError">
                  You should close this page and re-access it from the portal.
                </div>
                <div class="col col-12 text-center mt-5" v-if="!hasPermissionError && !noRetry">
                  <button @click="retrySubmission" class="btn btn-secondary btn-lg">Retry</button>
                  <!-- <button v-if="submissionErrorCount > 0" @click="saveSubmission" class="btn btn-success btn-lg ml-3">Save for Later</button> -->
                </div>

              <div v-if="isSaved && formId">
                <div class="mt-5 alert alert-info info-block">
                  <span class="bold">Note:</span> Your progress was saved but not finalized, please use the code <span class="text-danger">{{ formId }}</span> to re-access and finalize the submission of this form.
                </div>
              </div>
              </div>
            </template>
            <template v-else>
              <div v-if="step > 1" class="card-header text-left">
                <h5 v-if="step === 2" class="m-0">Care Response</h5>
                <h5 v-if="step === 3" class="m-0">Consumer Health Status</h5>
                <h5 v-if="step === 4" class="m-0">Home/Family Environment</h5>
                <h5 v-if="step === 5" class="m-0">COVID-19</h5>
                <h5 v-if="step === 6" class="m-0">Caregiver</h5>
                <h5 v-if="step === 7" class="m-0">Client Satisfaction</h5>
              </div>
              <template>
                <div class="card-body workarea text-left" v-if="step === 0">
                    <div class="col col-12 text-center">
                      <div v-if="continueSaved">
                        <div class="input-error" v-if="formError && formMessage">
                          {{ formMessage }}
                        </div>

                          <div class="row text-left">
                            <div class="col col-9 mx-auto">
                                <b-form @submit="findAssessment" v-if="!record || !record.id">
                                    <div class="col col-12 text-center mt-4">
                                      <b-form-input
                                          id="form_id"
                                          v-model.trim="formId"
                                          :disabled="isSubmitting"
                                          class="col-6 mx-auto"
                                          placeholder="enter code here"
                                          autocomplete="new-password"
                                        />
                                    </div>
                                    <div class="col col-12 mt-4 text-center">
                                      <button type="submit" class="btn btn-success mr-2">Next</button>
                                      <button type="button" class="btn btn-secondary" @click="cancelContinue">Cancel</button>
                                    </div>
                                </b-form>
                                <template v-else>
                                    <div class="col col-12 text-center mb-4 mt-4">
                                      We found the assessment for <span class="bold">{{ record.client_name }}</span>, what would you like to do?
                                    </div>
                                    <div class="col col-12 text-center">
                                      <button type="button" class="btn btn-success mr-2" @click="loadAssessment">Review &amp; Submit</button>
                                      <button type="button" class="btn btn-secondary" @click="cancelContinue">Cancel</button>
                                    </div>
                                </template>
                            </div>
                          </div>
                      </div>
                      <div v-else>
                        <button type="button" class="btn btn-success btn-lg mt-3" @click="startNew">Start a new Assessment</button>
                        <div class="clearfix">&nbsp;</div>
                        <button type="button" class="btn btn-info btn-lg mt-3" @click="continueAssessment">Finalize a saved Assessment</button>
                      </div>
                    </div>
                </div>
                  <b-form v-else @submit="saveSurvey">
                <div class="card-body workarea text-left">
                    <div class="col col-6" v-if="step === 1">
                      <b-form-group
                                    label=""
                                    :class="{ 'required-input': true }"
                                    label-for="location_id"
                                  >
                                    <b-form-select
                                      id="location_id"
                                      v-model.trim="$v.form.location_id.$model"
                                      :options="locationOptions"
                                      @change="loadClients"
                                      :class="{ 'has-error': $v.form.location_id.$error }"
                                      :disabled="isSubmitting"
                                      autofocus
                                      autocomplete="new-password"
                                    />
                                    <div
                                      v-if="$v.form.location_id.$dirty && $v.form.location_id.$error"
                                      class="input-error"
                                    >
                                      {{ getLocationErrorMessage }}
                                    </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="client-name-group"
                          label="Client Name"
                          class="required-input"
                          label-for="client_name"
                      >
                        <v-select
                          v-model.trim="$v.form.client_id.$model"
                          :options="clientOptions"
                          :reduce="client => client.value"
                          @input="selectClient"
                          :disabled="isSubmitting || !$v.form.location_id.$model"
                          :clearable="false"
                          label="text"
                        />
                        <div
                          v-if="$v.form.client_id.$dirty && $v.form.client_id.$error"
                          class="input-error"
                        >
                        {{ getClientIDErrorMessage }}
                        </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="client-number-group"
                          label="Client Phone Number"
                          class="required-input"
                          label-for="client_number"
                      >
                        <b-form-input
                          id="client_number"
                          v-model.trim="$v.form.client_number.$model"
                          :class="{ 'has-error': $v.form.client_number.$error }"
                          :disabled="isSubmitting || !$v.form.location_id.$model"
                          :formatter="acceptNumber"
                          placeholder="enter phone number"
                          autocomplete="new-password"
                        />
                        <div
                          v-if="$v.form.client_number.$dirty && $v.form.client_number.$error"
                          class="input-error"
                        >
                        {{ getClientPhoneErrorMessage }}
                        </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="supervisor-name-group-1"
                          label="Client Service Supervisor Name"
                          class="required-input"
                          label-for="supervisor_name"
                      >
                        <b-form-input
                          id="supervisor_name"
                          v-model.trim="$v.form.supervisor_name.$model"
                          :class="{ 'has-error': $v.form.supervisor_name.$error }"
                          :disabled="isSubmitting"
                          placeholder="enter a name"
                          autocomplete="new-password"
                        />
                        <div
                          v-if="$v.form.supervisor_name.$dirty && $v.form.supervisor_name.$error"
                          class="input-error"
                        >
                        {{ getSupervisorNameErrorMessage }}
                        </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                    </div>
                    <div class="col col-12" v-if="step === 2">
                      <b-form-group
                          id="aide-help-group-1"
                          label="What does your aid help you with?"
                          class="required-input label-header"
                      >
                        <div v-for="care in caresArr" :key="care.id" class="col col-6 p-0">
                          <b-form-checkbox
                            :id="'aide_' + care.id"
                            value="1"
                            v-model.trim="$v.form[`aide_care_${care.id}`].$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          /> <label :for="'aide_' + care.id">
                              <span class="option-label">{{ care.label }}</span>
                              </label>
                        </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="service-match-group-1"
                          label="Does the description of services match the service authorization?"
                          class="required-input label-header"
                      >
                          <b-form-radio
                            id="service_match_yes"
                            name="service_match"
                            value="1"
                            v-model.trim="$v.form.service_match.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="service_match_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="service_match_no"
                            name="service_match"
                            value="0"
                            v-model.trim="$v.form.service_match.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="service_match_no">
                            <span class="option-label">No</span>
                          </label>
                        <div
                          v-if="$v.form.service_match.$dirty && $v.form.service_match.$error"
                          class="input-error col col-12"
                        >
                        {{ getServiceMatchErrorMessage }}
                        </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="caresify-binder-group-1"
                          label="Is there a Caresify Binder available?"
                          class="required-input label-header"
                      >
                          <b-form-radio
                            id="binder_yes"
                            name="binder_available"
                            value="1"
                            v-model.trim="$v.form.binder_available.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="binder_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="binder_no"
                            name="binder_available"
                            value="0"
                            v-model.trim="$v.form.binder_available.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="binder_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                          v-if="$v.form.binder_available.$dirty && $v.form.binder_available.$error"
                            class="input-error col col-12"
                          >
                          {{ getBinderErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="dcw-group-1"
                          label="Are the DCW performing the tasks listed on the care plan?"
                          class="required-input label-header mb-0"
                      >
                          <b-form-radio
                            id="dcw_yes"
                            name="dcw"
                            value="1"
                            v-model.trim="$v.form.dcw.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="dcw_no"
                            name="dcw"
                            value="0"
                            v-model.trim="$v.form.dcw.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_no">
                            <span class="option-label">No</span>
                          </label>
                          <b-form-radio
                            id="dcw_others"
                            name="dcw"
                            value="2"
                            v-model.trim="$v.form.dcw.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_others">
                            <span class="option-label">Others</span>
                          </label>
                          <b-form-input
                          id="dcw_notes"
                          v-model.trim="$v.form.dcw_notes.$model"
                          autofocus
                          v-if="$v.form.dcw.$model === '2'"
                          :class="{ 'has-error': $v.form.dcw_notes.$error, 'col col-6 ml-2 d-inline': true }"
                          :disabled="isSubmitting"
                          placeholder=""
                          autocomplete="new-password"
                        />
                          <div
                            v-if="$v.form.dcw.$dirty && $v.form.dcw.$error"
                            class="input-error col col-12 mb-3"
                          >
                          {{ getDCWErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="care-plan-group-1"
                          label="Does the Consumer have needs not identified on the care plan?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="care_plan_yes"
                            name="care_plan"
                            value="1"
                            v-model.trim="$v.form.care_plan.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="care_plan_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="care_plan_no"
                            name="care_plan"
                            value="0"
                            v-model.trim="$v.form.care_plan.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="care_plan_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.care_plan.$dirty && $v.form.care_plan.$error"
                            class="input-error col col-12"
                          >
                          {{ getCarePlanErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <template v-if="$v.form.care_plan && $v.form.care_plan.$model === '1'">
                        <b-form-group
                            id="care-group-1"
                            label="Which task should be added to care plan?"
                            class="required-input label-header mb-0"
                        >
                          <div v-for="care in caresArr" :key="care.id" class="col col-6 p-0">
                            <b-form-checkbox
                              :id="'task_' + care.id"
                              value="1"
                              v-model.trim="$v.form[`task_care_${care.id}`].$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            /> <label :for="'task_' + care.id">
                                <span class="option-label">{{ care.label }}</span>
                                </label>
                          </div>
                        </b-form-group>
                        <div class="d-inline ml-3 label-header">
                          <b-form-checkbox
                              id="care_others"
                              value="1"
                              v-model.trim="$v.form.care_others.$model"
                              :disabled="isSubmitting"
                              class="d-inline"
                            /> <label for="care_others">
                                <span class="option-label">Other</span>
                                </label>
                        <b-form-input
                          id="care_plan_notes"
                          autofocus
                          v-if="$v.form.care_others.$model === '1'"
                          v-model.trim="$v.form.care_plan_notes.$model"
                          :class="{ 'has-error': $v.form.care_plan_notes.$error, 'col col-6 ml-2 d-inline': true }"
                          :disabled="isSubmitting"
                          placeholder=""
                          autocomplete="new-password"
                        />
                      </div>
                        <div class="clearfix">&nbsp;</div>
                      </template>
                    </div>
                    <div class="col col-12" v-if="step === 3">
                      <b-form-group
                          label="Have there been any changes to your health?"
                          class="required-input"
                          label-for="health_changes"
                      >
                        <b-form-input
                          id="health_changes"
                          autofocus
                          v-model.trim="$v.form.health_changes.$model"
                          :class="{ 'has-error': $v.form.health_changes.$error, 'col-6': true }"
                          :disabled="isSubmitting"
                          placeholder="Your answer"
                          autocomplete="new-password"
                        />
                        <div
                          v-if="$v.form.health_changes.$dirty && $v.form.health_changes.$error"
                          class="input-error"
                        >
                        {{ getHealthChangesErrorMessage }}
                        </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Date of last medical appointment"
                            class="required-input"
                            label-for="date_last_appointment"
                        >
                          <b-form-datepicker
                            id="date_last_appointment"
                            placeholder="select a date"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :max="todayDate"
                            locale="en"
                            v-model.trim="$v.form.date_last_appointment.$model"
                            :class="{ 'has-error': $v.form.date_last_appointment.$error, 'col-3 col-xs-6': true }"
                            :disabled="isSubmitting || ($v.form.date_last_appointment_unknown && $v.form.date_last_appointment_unknown.$model === '1')"
                            no-header
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.date_last_appointment.$dirty && $v.form.date_last_appointment.$error"
                            class="input-error"
                          >
                          {{ getLastAppointmentDateErrorMessage }}
                          </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-checkbox
                            id="date_last_appointment_unknown"
                            v-model.trim="$v.form.date_last_appointment_unknown.$model"
                            value="1"
                            class="date-appointment-unknown d-inline"
                            :disabled="isSubmitting"
                          />
                        <label for="date_last_appointment_unknown" class="mb-3">Unknown</label>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Date of next medical appointment"
                            class="required-input"
                            label-for="date_next_appointment"
                        >
                          <b-form-datepicker
                            id="date_next_appointment"
                            v-model.trim="$v.form.date_next_appointment.$model"
                            :class="{ 'has-error': $v.form.date_next_appointment.$error, 'col-3 col-xs-6': true }"
                            :disabled="isSubmitting || ($v.form.date_next_appointment_unknown && $v.form.date_next_appointment_unknown.$model === '1')"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            placeholder="select a date"
                            :min="todayDate"
                            locale="en"
                            no-header
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.date_next_appointment.$dirty && $v.form.date_next_appointment.$error"
                            class="input-error"
                          >
                          {{ getNextAppointmentDateErrorMessage }}
                          </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-checkbox
                            id="date_next_appointment_unknown"
                            v-model.trim="$v.form.date_next_appointment_unknown.$model"
                            value="1"
                            class="date-appointment-unknown d-inline"
                            :disabled="isSubmitting"
                          />
                        <label for="date_next_appointment_unknown" class="mb-3">Unknown</label>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Since last revisit, have you:"
                            class="required-input label-header"
                        >
                          <div class="row m-0">
                            <div class="col col-6 p-0">
                              <b-form-checkbox
                                id="revisit_hospital"
                                value="1"
                                v-model.trim="$v.form.revisit_hospital.$model"
                                :disabled="isSubmitting || $v.form.revisit_none.$model === '1'"
                                class="d-inline ml-3"
                              />
                              <label for="revisit_hospital">
                                <span class="option-label">Visited the hospital</span>
                              </label>
                            </div>
                            <div class="col col-6 p-0">
                              <b-form-checkbox
                                id="revisit_uti"
                                value="1"
                                v-model.trim="$v.form.revisit_uti.$model"
                                :disabled="isSubmitting || $v.form.revisit_none.$model === '1'"
                                class="d-inline ml-3"
                              />
                              <label for="revisit_uti">
                                <span class="option-label">Urinary Tract Infection</span>
                              </label>
                            </div>
                            <div class="col col-6 p-0">
                              <b-form-checkbox
                                id="revisit_uri"
                                value="1"
                                v-model.trim="$v.form.revisit_uri.$model"
                                :disabled="isSubmitting || $v.form.revisit_none.$model === '1'"
                                class="d-inline ml-3"
                              />
                              <label for="revisit_uri">
                                <span class="option-label">Upper Respiratory Infection</span>
                              </label>
                            </div>
                            <div class="col col-6 p-0">
                              <b-form-checkbox
                                id="revisit_pneumonia"
                                value="1"
                                v-model.trim="$v.form.revisit_pneumonia.$model"
                                :disabled="isSubmitting || $v.form.revisit_none.$model === '1'"
                                class="d-inline ml-3"
                              />
                              <label for="revisit_pneumonia">
                                <span class="option-label">Pneumonia</span>
                              </label>
                            </div>
                            <div class="col col-6 p-0">
                              <b-form-checkbox
                                id="revisit_covid"
                                value="1"
                                v-model.trim="$v.form.revisit_covid.$model"
                                :disabled="isSubmitting || $v.form.revisit_none.$model === '1'"
                                class="d-inline ml-3"
                              />
                              <label for="revisit_covid">
                                <span class="option-label">COVID-19</span>
                              </label>
                            </div>
                            <div class="col col-6 p-0">
                              <b-form-checkbox
                                id="revisit_none"
                                value="1"
                                v-model.trim="$v.form.revisit_none.$model"
                                @change="revisitNoneAction"
                                :disabled="isSubmitting"
                                class="d-inline ml-3"
                              />
                              <label for="revisit_none">
                                <span class="option-label">None of the above</span>
                              </label>
                            </div>
                          </div>
                          <div class="col-10">
                            <b-form-checkbox
                                id="revisit_other"
                                value="1"
                                v-model.trim="$v.form.revisit_other.$model"
                                :disabled="isSubmitting || $v.form.revisit_none.$model === '1'"
                                class="d-inline"
                            />
                            <label for="revisit_other">
                                <span class="option-label">Other</span>
                              </label>
                            <b-form-input
                              id="revisit_other_notes"
                              autofocus
                              v-if="$v.form.revisit_other.$model === '1'"
                              v-model.trim="$v.form.revisit_other_notes.$model"
                              :class="{ 'has-error': $v.form.revisit_other_notes.$error, 'col col-10 ml-2 d-inline': true }"
                              :disabled="isSubmitting"
                              placeholder=""
                              autocomplete="new-password"
                            />
                          </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Please describe what happened?"
                            class="required-input label-header"
                            v-if="($v.form.revisit_hospital.$model === '1' || $v.form.revisit_uti.$model === '1' || $v.form.revisit_uri.$model === '1' || $v.form.revisit_pneumonia.$model === '1' || $v.form.revisit_covid.$model === '1') && $v.form.revisit_none.$model !== '1'"
                            label-for="revisit_reasons"
                        >
                          <b-form-input
                            id="revisit_reasons"
                            v-model.trim="$v.form.revisit_reasons.$model"
                            :class="{ 'has-error': $v.form.revisit_reasons.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.revisit_reasons.$dirty && $v.form.revisit_reasons.$error"
                            class="input-error"
                          >
                          {{ getRevisitReasonsErrorMessage }}
                          </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Since Last revisit have you fallen?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="revisit_fallen_yes"
                            name="revisit_fallen"
                            value="1"
                            v-model.trim="$v.form.revisit_fallen.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="revisit_fallen_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="revisit_fallen_no"
                            name="revisit_fallen"
                            value="0"
                            v-model.trim="$v.form.revisit_fallen.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="revisit_fallen_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.revisit_fallen.$dirty && $v.form.revisit_fallen.$error"
                            class="input-error col col-12"
                          >
                          {{ getRevisitFallenErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.revisit_fallen && $v.form.revisit_fallen.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Was this reported? What happened? Witnessed? Unwitnessed? During Shift, etc."
                            class="required-input label-header"
                            label-for="revisit_fallen_notes"
                        >
                          <b-form-input
                            id="revisit_fallen_notes"
                            autofocus
                            v-model.trim="$v.form.revisit_fallen_notes.$model"
                            :class="{ 'has-error': $v.form.revisit_fallen_notes.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.revisit_fallen_notes.$dirty && $v.form.revisit_fallen_notes.$error"
                            class="input-error"
                          >
                          {{ getRevisitFallenNotesErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Do you set up your own medications?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="medication_self_setup_yes"
                            name="medication_self_setup"
                            value="1"
                            v-model.trim="$v.form.medication_self_setup.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="medication_self_setup_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="medication_self_setup_no"
                            name="medication_self_setup"
                            value="0"
                            v-model.trim="$v.form.medication_self_setup.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="medication_self_setup_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.medication_self_setup.$dirty && $v.form.medication_self_setup.$error"
                            class="input-error col col-12"
                          >
                          {{ getMedicationSelfSetupErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.medication_self_setup && $v.form.medication_self_setup.$model === '0'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Who sets it up?"
                            class="required-input label-header"
                            label-for="medication_self_setup_notes"
                        >
                          <b-form-input
                            id="medication_self_setup_notes"
                            v-model.trim="$v.form.medication_self_setup_notes.$model"
                            :class="{ 'has-error': $v.form.medication_self_setup_notes.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.medication_self_setup_notes.$dirty && $v.form.medication_self_setup_notes.$error"
                            class="input-error"
                          >
                          {{ getMedicationSelfSetupNotesErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Do you know where to call if you run out of medications?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="medication_helpline_yes"
                            name="medication_helpline"
                            value="1"
                            v-model.trim="$v.form.medication_helpline.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="medication_helpline_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="medication_helpline_no"
                            name="medication_helpline"
                            value="0"
                            v-model.trim="$v.form.medication_helpline.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="medication_helpline_no">
                            <span class="option-label">No</span>
                          </label>
                          <b-form-radio
                            id="medication_helpline_others"
                            name="medication_helpline"
                            value="2"
                            v-model.trim="$v.form.medication_helpline.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="medication_helpline_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="medication_helpline_other"
                                autofocus
                                v-if="$v.form.medication_helpline.$model === '2'"
                                v-model.trim="$v.form.medication_helpline_other.$model"
                                :class="{ 'has-error': $v.form.medication_helpline_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                          <div
                            v-if="$v.form.medication_helpline.$dirty && $v.form.medication_helpline.$error"
                            class="input-error col col-12"
                          >
                          {{ getMedicationHelplineErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Are you aware that the aide is not permitted to administer your medication?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="aide_no_med_admin_yes"
                            name="aide_no_med_admin"
                            value="1"
                            v-model.trim="$v.form.aide_no_med_admin.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="aide_no_med_admin_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="aide_no_med_admin_no"
                            name="aide_no_med_admin"
                            value="0"
                            v-model.trim="$v.form.aide_no_med_admin.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="aide_no_med_admin_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.aide_no_med_admin.$dirty && $v.form.aide_no_med_admin.$error"
                            class="input-error col col-12"
                          >
                          {{ getAideNoMedsAdminErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Are your aides reminding you to take your medications?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="aide_reminder_yes"
                            name="aide_reminder"
                            value="1"
                            v-model.trim="$v.form.aide_reminder.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="aide_reminder_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="aide_reminder_no"
                            name="aide_reminder"
                            value="0"
                            v-model.trim="$v.form.aide_reminder.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="aide_reminder_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.aide_reminder.$dirty && $v.form.aide_reminder.$error"
                            class="input-error col col-12"
                          >
                          {{ getAideReminderErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Have there been any medication changes?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="medication_changes_yes"
                            name="medication_changes"
                            value="1"
                            v-model.trim="$v.form.medication_changes.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="medication_changes_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="medication_changes_no"
                            name="medication_changes"
                            value="0"
                            v-model.trim="$v.form.medication_changes.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="medication_changes_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.medication_changes.$dirty && $v.form.medication_changes.$error"
                            class="input-error col col-12"
                          >
                          {{ getMedicationChangesErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.medication_changes && $v.form.medication_changes.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Please describe these changes"
                            class="required-input label-header"
                            label-for="medication_changes_notes"
                        >
                          <b-form-input
                            id="medication_changes_notes"
                            autofocus
                            v-model.trim="$v.form.medication_changes_notes.$model"
                            :class="{ 'has-error': $v.form.medication_changes_notes.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.medication_changes_notes.$dirty && $v.form.medication_changes_notes.$error"
                            class="input-error"
                          >
                          {{ getMedicationChangesNotesErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Weight Changes?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="weight_change_gain"
                            name="weight_change"
                            value="1"
                            v-model.trim="$v.form.weight_change.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="weight_change_gain">
                            <span class="option-label">Gained weight</span>
                          </label>
                          <b-form-radio
                            id="weight_change_loss"
                            name="weight_change"
                            value="2"
                            v-model.trim="$v.form.weight_change.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="weight_change_loss">
                            <span class="option-label">Lost weight</span>
                          </label>
                          <b-form-radio
                            id="weight_change_neutral"
                            name="weight_change"
                            value="3"
                            v-model.trim="$v.form.weight_change.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="weight_change_neutral">
                            <span class="option-label">No change</span>
                          </label>
                          <div
                            v-if="$v.form.weight_change.$dirty && $v.form.weight_change.$error"
                            class="input-error col col-12"
                          >
                          {{ getWeightChangeErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.weight_change && ['1', '2'].includes($v.form.weight_change.$model)">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Reason for the change?"
                            class="required-input label-header"
                            label-for="weight_change_reason"
                        >
                          <b-form-input
                            id="weight_change_reason"
                            autofocus
                            v-model.trim="$v.form.weight_change_reason.$model"
                            :class="{ 'has-error': $v.form.weight_change_reason.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.weight_change_reason.$dirty && $v.form.weight_change_reason.$error"
                            class="input-error"
                          >
                          {{ getWeightChangeReasonErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Have you gotten a flu shot this year?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="flushot_got_yes"
                            name="flushot_got"
                            value="1"
                            v-model.trim="$v.form.flushot_got.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="flushot_got_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="flushot_got_no"
                            name="flushot_got"
                            value="0"
                            v-model.trim="$v.form.flushot_got.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="flushot_got_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.flushot_got.$dirty && $v.form.flushot_got.$error"
                            class="input-error col col-12"
                          >
                          {{ getFlushotGotErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Are you considering getting a flu shot this year?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="flushot_decision_yes"
                            name="flushot_decision"
                            value="1"
                            v-model.trim="$v.form.flushot_decision.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="flushot_decision_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="flushot_decision_no"
                            name="flushot_decision"
                            value="2"
                            v-model.trim="$v.form.flushot_decision.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="flushot_decision_no">
                            <span class="option-label">No</span>
                          </label>
                          <b-form-radio
                            id="flushot_decision_maybe"
                            name="flushot_decision"
                            value="3"
                            v-model.trim="$v.form.flushot_decision.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="flushot_decision_maybe">
                            <span class="option-label">Maybe</span>
                          </label>
                          <div
                            v-if="$v.form.flushot_decision.$dirty && $v.form.flushot_decision.$error"
                            class="input-error col col-12"
                          >
                          {{ getFlushotDecisionErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Are you experiencing a fever?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="fever_yes"
                            name="fever"
                            value="1"
                            v-model.trim="$v.form.fever.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="fever_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="fever_no"
                            name="fever"
                            value="0"
                            v-model.trim="$v.form.fever.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="fever_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.fever.$dirty && $v.form.fever.$error"
                            class="input-error col col-12"
                          >
                          {{ getFeverErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Are you experiencing shortness of breath?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="shortness_of_breath_yes"
                            name="shortness_of_breath"
                            value="1"
                            v-model.trim="$v.form.shortness_of_breath.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="shortness_of_breath_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="shortness_of_breath_no"
                            name="shortness_of_breath"
                            value="0"
                            v-model.trim="$v.form.shortness_of_breath.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="shortness_of_breath_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.shortness_of_breath.$dirty && $v.form.shortness_of_breath.$error"
                            class="input-error col col-12"
                          >
                          {{ getShortnessOfBreathErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Are you experiencing dry cough?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="dry_cough_yes"
                            name="dry_cough"
                            value="1"
                            v-model.trim="$v.form.dry_cough.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dry_cough_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="dry_cough_no"
                            name="dry_cough"
                            value="0"
                            v-model.trim="$v.form.dry_cough.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dry_cough_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.dry_cough.$dirty && $v.form.dry_cough.$error"
                            class="input-error col col-12"
                          >
                          {{ getDryCoughErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Have you been in direct contact with anyone who has tested positive for Coronavirus?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="covid_contact_yes"
                            name="covid_contact"
                            value="1"
                            v-model.trim="$v.form.covid_contact.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="covid_contact_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="covid_contact_no"
                            name="covid_contact"
                            value="0"
                            v-model.trim="$v.form.covid_contact.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="covid_contact_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.covid_contact.$dirty && $v.form.covid_contact.$error"
                            class="input-error col col-12"
                          >
                          {{ getCovidContactErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.covid_contact && $v.form.covid_contact.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="When was the last time you met with them physically?"
                            class="required-input label-header"
                            label-for="covid_contact_date"
                        >
                          <b-form-datepicker
                            id="covid_contact_date"
                            placeholder="select a date"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="en"
                            no-header
                            :max="todayDate"
                            v-model.trim="$v.form.covid_contact_date.$model"
                            :class="{ 'has-error': $v.form.covid_contact_date.$error, 'col-3 col-xs-6': true }"
                            :disabled="isSubmitting"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.covid_contact_date.$dirty && $v.form.covid_contact_date.$error"
                            class="input-error"
                          >
                          {{ getCovidContactDateErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                    </div>
                    <div class="col col-12" v-if="step === 4">
                      <b-form-group
                          id="living-situation-group-1"
                          label="Has there been a change in living situation or caregivers?"
                          class="required-input label-header mb-0"
                      >
                          <b-form-radio
                            id="living_situation_yes"
                            name="living_situation"
                            value="1"
                            v-model.trim="$v.form.living_situation.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="living_situation_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="living_situation_no"
                            name="living_situation"
                            value="0"
                            v-model.trim="$v.form.living_situation.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="living_situation_no">
                            <span class="option-label">No</span>
                          </label>
                          <b-form-radio
                            id="living_situation_other"
                            name="living_situation"
                            value="2"
                            v-model.trim="$v.form.living_situation.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="living_situation_other">
                            <span class="option-label">Others</span>
                          </label>
                          <b-form-input
                          id="living_situation_other"
                          autofocus
                          v-model.trim="$v.form.living_situation_other.$model"
                          v-if="$v.form.living_situation.$model === '2'"
                          :class="{ 'has-error': $v.form.living_situation_other.$error, 'col col-6 ml-2 d-inline': true }"
                          :disabled="isSubmitting"
                          placeholder=""
                          autocomplete="new-password"
                        />
                          <div
                            v-if="$v.form.living_situation.$dirty && $v.form.living_situation.$error"
                            class="input-error col col-12 mb-3"
                          >
                          {{ getLivingSituationErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.living_situation && $v.form.living_situation.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="Please explain"
                            class="required-input"
                            label-for="living_situation_notes"
                        >
                          <b-form-input
                            id="living_situation_notes"
                            v-model.trim="$v.form.living_situation_notes.$model"
                            :class="{ 'has-error': $v.form.living_situation_notes.$error, 'col-10 col-xs-6': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.living_situation_notes.$dirty && $v.form.living_situation_notes.$error"
                            class="input-error"
                          >
                          {{ getLivingSituationNotesErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="address-change-group-1"
                          label="Have you had a change in Home Address?"
                          class="required-input label-header mb-0"
                      >
                          <b-form-radio
                            id="address_change_yes"
                            name="address_change"
                            value="1"
                            v-model.trim="$v.form.address_change.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="address_change_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="address_change_no"
                            name="address_change"
                            value="0"
                            v-model.trim="$v.form.address_change.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="address_change_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.address_change.$dirty && $v.form.address_change.$error"
                            class="input-error col col-12 mb-3"
                          >
                          {{ getAddressChangeErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.address_change && $v.form.address_change.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="New address:"
                            class="required-input label-header"
                            label-for="new_address"
                        >
                          <b-form-input
                            id="new_address"
                            v-model.trim="$v.form.new_address.$model"
                            :class="{ 'has-error': $v.form.new_address.$error, 'col-10 col-xs-6': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.new_address.$dirty && $v.form.new_address.$error"
                            class="input-error"
                          >
                          {{ getNewAddressErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="children-in-home-group-1"
                          label="Are there children regularly in your home?"
                          class="required-input label-header mb-0"
                      >
                        <div class="row ml-0">
                          <div class="col col-6 p-0">
                            <b-form-radio
                              id="children_in_home_yes_livein"
                              name="children_in_home"
                              value="1"
                              v-model.trim="$v.form.children_in_home.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0 pl-4"
                            />
                            <label for="children_in_home_yes_livein">
                              <span class="option-label">Yes, they live with client</span>
                            </label>
                          </div>
                          <div class="col col-6 p-0">
                            <b-form-radio
                              id="children_in_home_yes_regular"
                              name="children_in_home"
                              value="2"
                              v-model.trim="$v.form.children_in_home.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0"
                            />
                            <label for="children_in_home_yes_regular">
                              <span class="option-label">Yes, they visit regularly</span>
                            </label>
                          </div>
                          <div class="col col-6 p-0">
                            <b-form-radio
                              id="children_in_home_yes_occassional"
                              name="children_in_home"
                              value="3"
                              v-model.trim="$v.form.children_in_home.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0 pl-4"
                            />
                            <label for="children_in_home_yes_occassional">
                              <span class="option-label">Yes, they visit on occasions</span>
                            </label>
                          </div>
                          <div class="col col-6 p-0">
                            <b-form-radio
                              id="children_in_home_no"
                              name="children_in_home"
                              value="0"
                              v-model.trim="$v.form.children_in_home.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0"
                            />
                            <label for="children_in_home_no">
                              <span class="option-label">No children</span>
                            </label>
                          </div>
                        </div>
                          <div
                            v-if="$v.form.children_in_home.$dirty && $v.form.children_in_home.$error"
                            class="input-error col col-12 mb-3"
                          >
                          {{ getChildrenInHomeErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.children_in_home && !['0', ''].includes($v.form.children_in_home.$model)">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="children-under-18-group-1"
                            label="Are the children under 18 years old?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="children_under_18_yes"
                              name="children_under_18"
                              value="1"
                              v-model.trim="$v.form.children_under_18.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="children_under_18_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="children_under_18_no"
                              name="children_under_18"
                              value="0"
                              v-model.trim="$v.form.children_under_18.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="children_under_18_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.children_under_18.$dirty && $v.form.children_under_18.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getChildrenUnder18ErrorMessage }}
                            </div>
                        </b-form-group>
                      </template>
                      <template v-if="$v.form.children_under_18 && $v.form.children_under_18.$model === '1' && $v.form.children_in_home && !['0', ''].includes($v.form.children_in_home.$model)">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="children-in-home-service-hours-group-1"
                            label="Are the children visiting during a time when we are providing service?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="children_in_home_service_hours_yes"
                              name="children_in_home_service_hours"
                              value="1"
                              v-model.trim="$v.form.children_in_home_service_hours.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="children_in_home_service_hours_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="children_in_home_service_hours_no"
                              name="children_in_home_service_hours"
                              value="0"
                              v-model.trim="$v.form.children_in_home_service_hours.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="children_in_home_service_hours_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.children_in_home_service_hours.$dirty && $v.form.children_in_home_service_hours.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getChildrenServiceHoursErrorMessage }}
                            </div>
                        </b-form-group>
                        <template v-if="$v.form.children_in_home_service_hours && $v.form.children_in_home_service_hours.$model === '1' && $v.form.children_under_18 && $v.form.children_under_18.$model === '1'">
                          <div class="clearfix">&nbsp;</div>
                          <b-form-group
                              id="child-abuse-clearance-group-1"
                              label="Are you aware that Pennsylvania State regulation requires that your caregivers must be fingerprinted for a child abuse clearance in order to provide services in your home?"
                              class="required-input label-header mb-0"
                          >
                              <b-form-radio
                                id="child_abuse_clearance_yes"
                                name="child_abuse_clearance"
                                value="1"
                                v-model.trim="$v.form.child_abuse_clearance.$model"
                                :disabled="isSubmitting"
                                class="d-inline ml-3"
                              />
                              <label for="child_abuse_clearance_yes">
                                <span class="option-label">Yes</span>
                              </label>
                              <b-form-radio
                                id="child_abuse_clearance_no"
                                name="child_abuse_clearance"
                                value="0"
                                v-model.trim="$v.form.child_abuse_clearance.$model"
                                :disabled="isSubmitting"
                                class="d-inline ml-3"
                              />
                              <label for="child_abuse_clearance_no">
                                <span class="option-label">No</span>
                              </label>
                              <div
                                v-if="$v.form.child_abuse_clearance.$dirty && $v.form.child_abuse_clearance.$error"
                                class="input-error col col-12 mb-3"
                              >
                              {{ getChildAbuseClearanceErrorMessage }}
                              </div>
                          </b-form-group>                          
                        </template>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Have there been any home safety changes?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="home_safety_changes_yes"
                            name="home_safety_changes"
                            value="1"
                            v-model.trim="$v.form.home_safety_changes.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="home_safety_changes_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="home_safety_changes_no"
                            name="home_safety_changes"
                            value="0"
                            v-model.trim="$v.form.home_safety_changes.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="home_safety_changes_no">
                            <span class="option-label">No</span>
                          </label>
                          <b-form-radio
                            id="home_safety_changes_other"
                            name="home_safety_changes"
                            value="2"
                            v-model.trim="$v.form.home_safety_changes.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="home_safety_changes_other">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="home_safety_changes_notes"
                                autofocus
                                v-if="$v.form.home_safety_changes.$model === '2'"
                                v-model.trim="$v.form.home_safety_changes_notes.$model"
                                :class="{ 'has-error': $v.form.home_safety_changes_notes.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                          <div
                            v-if="$v.form.home_safety_changes.$dirty && $v.form.home_safety_changes.$error"
                            class="input-error col col-12"
                          >
                          {{ getHomeSafetyChangesErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          label="Are there any community agencies active?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="active_community_agencies_yes"
                            name="active_community_agencies"
                            value="1"
                            v-model.trim="$v.form.active_community_agencies.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="active_community_agencies_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="active_community_agencies_no"
                            name="medication_self_setup"
                            value="0"
                            v-model.trim="$v.form.active_community_agencies.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="active_community_agencies_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.active_community_agencies.$dirty && $v.form.active_community_agencies.$error"
                            class="input-error col col-12"
                          >
                          {{ getActiveCommunityAgenciesErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.active_community_agencies && $v.form.active_community_agencies.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            label=""
                            class="required-input label-header"
                            label-for="active_community_agencies_notes"
                      >
                          <b-form-input
                            id="active_community_agencies_notes"
                            v-model.trim="$v.form.active_community_agencies_notes.$model"
                            :class="{ 'has-error': $v.form.active_community_agencies_notes.$error, 'col-8': true }"
                            :disabled="isSubmitting"
                            placeholder="Please name these agencies"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.active_community_agencies_notes.$dirty && $v.form.active_community_agencies_notes.$error"
                            class="input-error"
                          >
                          {{ getActiveCommunityAgenciesNotesErrorMessage }}
                          </div>
                      </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            id="emergency-numbers-group-1"
                            label="Are there emergency numbers available?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="emergency_numbers_yes"
                              name="emergency_numbers"
                              value="1"
                              v-model.trim="$v.form.emergency_numbers.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="emergency_numbers_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="emergency_numbers_no"
                              name="emergency_numbers"
                              value="0"
                              v-model.trim="$v.form.emergency_numbers.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="emergency_numbers_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.emergency_numbers.$dirty && $v.form.emergency_numbers.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getEmergencyNumbersErrorMessage }}
                            </div>
                        </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            id="emergency-system-group-1"
                            label="Emergency Call System?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="emergency_system_yes"
                              name="emergency_system"
                              value="1"
                              v-model.trim="$v.form.emergency_system.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="emergency_system_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="emergency_system_no"
                              name="emergency_system"
                              value="0"
                              v-model.trim="$v.form.emergency_system.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="emergency_system_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.emergency_system.$dirty && $v.form.emergency_system.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getEmergencySystemErrorMessage }}
                            </div>
                        </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            label="Who is your backup caregiver?"
                            class="required-input label-header"
                            label-for="backup_caregiver"
                      >
                          <b-form-input
                            id="backup_caregiver"
                            v-model.trim="$v.form.backup_caregiver.$model"
                            :class="{ 'has-error': $v.form.backup_caregiver.$error, 'col-5': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.backup_caregiver.$dirty && $v.form.backup_caregiver.$error"
                            class="input-error"
                          >
                          {{ getBackupCaregiverErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            label="What is their phone number?"
                            class="required-input label-header"
                            label-for="backup_caregiver_phone"
                      >
                          <b-form-input
                            id="backup_caregiver_phone"
                            v-model.trim="$v.form.backup_caregiver_phone.$model"
                            :class="{ 'has-error': $v.form.backup_caregiver_phone.$error, 'col-5': true }"
                            :disabled="isSubmitting"
                            placeholder="enter a phone number"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.backup_caregiver_phone.$dirty && $v.form.backup_caregiver_phone.$error"
                            class="input-error"
                          >
                          {{ getBackupCaregiverPhoneErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                          id="priority-status-group-1"
                          label="Priority Status?"
                          class="required-input label-header mb-0"
                      >
                            <b-form-radio
                              id="priority_status_1"
                              name="priority_status"
                              value="1"
                              v-model.trim="$v.form.priority_status.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0 pl-4"
                            />
                            <label for="priority_status_1">
                              <span class="option-label">Level 1</span>
                            </label>
                            <b-form-radio
                              id="priority_status_2"
                              name="priority_status"
                              value="2"
                              v-model.trim="$v.form.priority_status.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0 pl-4"
                            />
                            <label for="priority_status_2">
                              <span class="option-label">Level 2</span>
                            </label>
                            <b-form-radio
                              id="priority_status_3"
                              name="priority_status"
                              value="3"
                              v-model.trim="$v.form.priority_status.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3 p-0 pl-4"
                            />
                            <label for="priority_status_3">
                              <span class="option-label">Level 3</span>
                            </label>
                          <div
                            v-if="$v.form.priority_status.$dirty && $v.form.priority_status.$error"
                            class="input-error col col-12 mb-3"
                          >
                          {{ getPriorityStatusErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            label="Special care needs?"
                            class="required-input label-header"
                            label-for="special_care_needs"
                      >
                          <b-form-input
                            id="special_care_needs"
                            v-model.trim="$v.form.special_care_needs.$model"
                            :class="{ 'has-error': $v.form.special_care_needs.$error, 'col-5': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.special_care_needs.$dirty && $v.form.special_care_needs.$error"
                            class="input-error"
                          >
                          {{ getSpecialCareNeedsErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            id="anti-bacteria-soap-group-1"
                            label="Is there a supply of anti-bacterial soap or gel in the home?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="anti_bacteria_soap_yes"
                              name="anti_bacteria_soap"
                              value="1"
                              v-model.trim="$v.form.anti_bacteria_soap.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="anti_bacteria_soap_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="anti_bacteria_soap_no"
                              name="anti_bacteria_soap"
                              value="0"
                              v-model.trim="$v.form.anti_bacteria_soap.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="anti_bacteria_soap_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.anti_bacteria_soap.$dirty && $v.form.anti_bacteria_soap.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getAntiBacterialSoapErrorMessage }}
                            </div>
                        </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                      <b-form-group
                            id="gloves-supply-group-1"
                            label="Is there a supply of gloves in the home?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="gloves_supply_yes"
                              name="gloves_supply"
                              value="1"
                              v-model.trim="$v.form.gloves_supply.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="gloves_supply_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="gloves_supply_no"
                              name="gloves_supply"
                              value="0"
                              v-model.trim="$v.form.gloves_supply.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="gloves_supply_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.gloves_supply.$dirty && $v.form.gloves_supply.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getGlovesSupplyErrorMessage }}
                            </div>
                        </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                    </div>
                    <div class="col col-12" v-if="step === 5">
                        <b-form-group
                            id="caregivers-gloves-group"
                            label="Are your caregivers using gloves when they provide services?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_gloves_yes"
                              name="caregivers_gloves"
                              value="1"
                              v-model.trim="$v.form.caregivers_gloves.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_gloves_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_gloves_no"
                              name="caregivers_gloves"
                              value="0"
                              v-model.trim="$v.form.caregivers_gloves.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_gloves_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_gloves.$dirty && $v.form.caregivers_gloves.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversGlovesErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-mask-group"
                            label="Are your caregivers wearing masks when they provide services?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_mask_yes"
                              name="caregivers_mask"
                              value="1"
                              v-model.trim="$v.form.caregivers_mask.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_mask_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_mask_no"
                              name="caregiver_mask"
                              value="0"
                              v-model.trim="$v.form.caregivers_mask.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_mask_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_mask.$dirty && $v.form.caregivers_mask.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversMaskErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-wash-hands-group"
                            label="Are your caregivers washing their hands regularly when they are providing services to you?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_wash_hands_yes"
                              name="caregivers_wash_hands"
                              value="1"
                              v-model.trim="$v.form.caregivers_wash_hands.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_wash_hands_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_wash_hands_no"
                              name="caregivers_wash_hands"
                              value="0"
                              v-model.trim="$v.form.caregivers_wash_hands.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_wash_hands_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_wash_hands.$dirty && $v.form.caregivers_wash_hands.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversWashHandsErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-social-distancing-group"
                            label="Are your caregivers practicing social distancing while they provide service to you?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_social_distancing_yes"
                              name="caregivers_social_distancing"
                              value="1"
                              v-model.trim="$v.form.caregivers_social_distancing.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_social_distancing_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_social_distancing_no"
                              name="caregivers_social_distancing"
                              value="0"
                              v-model.trim="$v.form.caregivers_social_distancing.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_social_distancing_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_social_distancing.$dirty && $v.form.caregivers_social_distancing.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversSocialDistancingErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-fevers-group"
                            label="Did any of your caregivers mention if they were experiencing fevers?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_fevers_yes"
                              name="caregivers_fevers"
                              value="1"
                              v-model.trim="$v.form.caregivers_fevers.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_fevers_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_fevers_no"
                              name="caregivers_fevers"
                              value="0"
                              v-model.trim="$v.form.caregivers_fevers.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_fevers_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_fevers.$dirty && $v.form.caregivers_fevers.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversFeversErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-shortness-of-breath-group"
                            label="Did any of your caregivers mention if they are experiencing shortness of breath?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_shortness_of_breath_yes"
                              name="caregivers_shortness_of_breath"
                              value="1"
                              v-model.trim="$v.form.caregivers_shortness_of_breath.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_shortness_of_breath_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_shortness_of_breath_no"
                              name="caregivers_shortness_of_breath"
                              value="0"
                              v-model.trim="$v.form.caregivers_shortness_of_breath.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_shortness_of_breath_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_shortness_of_breath.$dirty && $v.form.caregivers_shortness_of_breath.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversShortnessOfBreathErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-dry-cough-group"
                            label="Did any of your caregivers mention if they are experiencing a dry cough?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_dry_cough_yes"
                              name="caregivers_dry_cough"
                              value="1"
                              v-model.trim="$v.form.caregivers_dry_cough.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_dry_cough_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_dry_cough_no"
                              name="caregivers_dry_cough"
                              value="0"
                              v-model.trim="$v.form.caregivers_dry_cough.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_dry_cough_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.caregivers_dry_cough.$dirty && $v.form.caregivers_dry_cough.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversDryCoughErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregivers-covid-contact-group"
                            label="Did any of your caregivers mention if they have been in contact with anyone with the Corona Virus?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregivers_covid_contact_yes"
                              name="caregivers_covid_contact"
                              value="1"
                              v-model.trim="$v.form.caregivers_covid_contact.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_covid_contact_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregivers_covid_contact_no"
                              name="caregivers_covid_contact"
                              value="0"
                              v-model.trim="$v.form.caregivers_covid_contact.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregivers_covid_contact_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="caregivers_covid_contact_others"
                            name="caregivers_covid_contact"
                            value="2"
                            v-model.trim="$v.form.caregivers_covid_contact.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="caregivers_covid_contact_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="caregivers_covid_contact_other"
                                autofocus
                                v-if="$v.form.caregivers_covid_contact.$model === '2'"
                                v-model.trim="$v.form.caregivers_covid_contact_other.$model"
                                :class="{ 'has-error': $v.form.caregivers_covid_contact_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.caregivers_covid_contact.$dirty && $v.form.caregivers_covid_contact.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiversCovidContactErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                    </div>
                    <div class="col col-12" v-if="step === 6">
                        <b-form-group
                            label="When was the last time that you had an aide?"
                            class="required-input label-header"
                            label-for="date_last_aide"
                        >
                          <b-form-datepicker
                            id="date_last_aide"
                            placeholder="select a date"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :max="todayDate"
                            locale="en"
                            no-header
                            v-model.trim="$v.form.date_last_aide.$model"
                            :class="{ 'has-error': $v.form.date_last_aide.$error, 'col-3 col-xs-6': true }"
                            :disabled="isSubmitting"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.date_last_aide.$dirty && $v.form.date_last_aide.$error"
                            class="input-error"
                          >
                          {{ getLastAideDateErrorMessage }}
                          </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="aide-on-time-group"
                            label="Does your aide show up on time?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="aide_on_time_yes"
                              name="aide_on_time"
                              value="1"
                              v-model.trim="$v.form.aide_on_time.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="aide_on_time_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="aide_on_time_no"
                              name="aide_on_time"
                              value="0"
                              v-model.trim="$v.form.aide_on_time.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="aide_on_time_no">
                              <span class="option-label">No</span>
                            </label>
                            <div
                              v-if="$v.form.aide_on_time.$dirty && $v.form.aide_on_time.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getAideOnTimeErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-per-org-policy-group"
                            label="Does the DCW demonstrate the ability to provide activities per org policy?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_per_org_policy_yes"
                              name="dcw_per_org_policy"
                              value="1"
                              v-model.trim="$v.form.dcw_per_org_policy.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_per_org_policy_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_per_org_policy_no"
                              name="dcw_per_org_policy"
                              value="0"
                              v-model.trim="$v.form.dcw_per_org_policy.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_per_org_policy_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_per_org_policy_others"
                            name="dcw_per_org_policy"
                            value="2"
                            v-model.trim="$v.form.dcw_per_org_policy.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_per_org_policy_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_per_org_policy_others"
                                autofocus
                                v-if="$v.form.dcw_per_org_policy.$model === '2'"
                                v-model.trim="$v.form.dcw_per_org_policy_other.$model"
                                :class="{ 'has-error': $v.form.dcw_per_org_policy_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_per_org_policy.$dirty && $v.form.dcw_per_org_policy.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWPerOrgPolicyErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-tasks-match-plan-group"
                            label="Do the tasks described by DCW match care plan?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_tasks_match_plan_yes"
                              name="dcw_tasks_match_plan"
                              value="1"
                              v-model.trim="$v.form.dcw_tasks_match_plan.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_tasks_match_plan_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_tasks_match_plan_no"
                              name="dcw_tasks_match_plan"
                              value="0"
                              v-model.trim="$v.form.dcw_tasks_match_plan.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_tasks_match_plan_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_tasks_match_plan_others"
                            name="dcw_tasks_match_plan"
                            value="2"
                            v-model.trim="$v.form.dcw_tasks_match_plan.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_tasks_match_plan_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_tasks_match_plan_other"
                                autofocus
                                v-if="$v.form.dcw_tasks_match_plan.$model === '2'"
                                v-model.trim="$v.form.dcw_tasks_match_plan_other.$model"
                                :class="{ 'has-error': $v.form.dcw_tasks_match_plan_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_tasks_match_plan.$dirty && $v.form.dcw_tasks_match_plan.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWTasksMatchPlanErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-instructions-group"
                            label="Instructions provided to DCW?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_instructions_yes"
                              name="dcw_instructions"
                              value="1"
                              v-model.trim="$v.form.dcw_instructions.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_instructions_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_instructions_no"
                              name="dcw_instructions"
                              value="0"
                              v-model.trim="$v.form.dcw_instructions.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_instructions_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_instructions_others"
                            name="dcw_instructions"
                            value="2"
                            v-model.trim="$v.form.dcw_instructions.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_instructions_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_instructions_others"
                                autofocus
                                v-if="$v.form.dcw_instructions.$model === '2'"
                                v-model.trim="$v.form.dcw_instructions_other.$model"
                                :class="{ 'has-error': $v.form.dcw_instructions_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_instructions.$dirty && $v.form.dcw_instructions.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWInstructionsErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="caregiver-plan-changes-group"
                            label="Is the caregiver aware of any assignment or care plan changes?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="caregiver_plan_changes_yes"
                              name="caregiver_plan_changes"
                              value="1"
                              v-model.trim="$v.form.caregiver_plan_changes.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregiver_plan_changes_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="caregiver_plan_changes_no"
                              name="caregiver_plan_changes"
                              value="0"
                              v-model.trim="$v.form.caregiver_plan_changes.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="caregiver_plan_changes_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="caregiver_plan_changes_others"
                            name="caregiver_plan_changes"
                            value="2"
                            v-model.trim="$v.form.caregiver_plan_changes.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="caregiver_plan_changes_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_tasks_match_plan_other"
                                autofocus
                                v-if="$v.form.caregiver_plan_changes.$model === '2'"
                                v-model.trim="$v.form.caregiver_plan_changes_other.$model"
                                :class="{ 'has-error': $v.form.caregiver_plan_changes_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.caregiver_plan_changes.$dirty && $v.form.caregiver_plan_changes.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCaregiverPlanChangesErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="competency-completed-group"
                            label="Competency Completed?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="competency_completed_yes"
                              name="competency_completed"
                              value="1"
                              v-model.trim="$v.form.competency_completed.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="competency_completed_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="competency_completed_no"
                              name="competency_completed"
                              value="0"
                              v-model.trim="$v.form.competency_completed.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="competency_completed_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="competency_completed_others"
                            name="competency_completed"
                            value="2"
                            v-model.trim="$v.form.competency_completed.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="competency_completed_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="competency_completed_other"
                                autofocus
                                v-if="$v.form.competency_completed.$model === '2'"
                                v-model.trim="$v.form.competency_completed_other.$model"
                                :class="{ 'has-error': $v.form.competency_completed_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.competency_completed.$dirty && $v.form.competency_completed.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCompetencyCompletedErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-demonstrate-supply-group"
                            label="Can the DCW demonstrate supply of gloves, soap, sanitizer, mask?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_demonstrate_supply_yes"
                              name="dcw_demonstrate_supply"
                              value="1"
                              v-model.trim="$v.form.dcw_demonstrate_supply.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_demonstrate_supply_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_demonstrate_supply_no"
                              name="dcw_demonstrate_supply"
                              value="0"
                              v-model.trim="$v.form.dcw_demonstrate_supply.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_demonstrate_supply_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_demonstrate_supply_others"
                            name="dcw_demonstrate_supply"
                            value="2"
                            v-model.trim="$v.form.dcw_demonstrate_supply.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_demonstrate_supply_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_demonstrate_supply_other"
                                autofocus
                                v-if="$v.form.dcw_demonstrate_supply.$model === '2'"
                                v-model.trim="$v.form.dcw_demonstrate_supply_other.$model"
                                :class="{ 'has-error': $v.form.dcw_demonstrate_supply_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_demonstrate_supply.$dirty && $v.form.dcw_demonstrate_supply.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWDemonstrateSupplyErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-additional-supply-group"
                            label="Can the DCW describe how to obtain additional supplies?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_additional_supply_yes"
                              name="dcw_additional_supply"
                              value="1"
                              v-model.trim="$v.form.dcw_additional_supply.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_additional_supply_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_additional_supply_no"
                              name="dcw_additional_supply"
                              value="0"
                              v-model.trim="$v.form.dcw_additional_supply.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_additional_supply_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_additional_supply_others"
                            name="dcw_additional_supply"
                            value="2"
                            v-model.trim="$v.form.dcw_additional_supply.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_additional_supply_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_additional_supply_other"
                                autofocus
                                v-if="$v.form.dcw_additional_supply.$model === '2'"
                                v-model.trim="$v.form.dcw_additional_supply_other.$model"
                                :class="{ 'has-error': $v.form.dcw_additional_supply_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_additional_supply.$dirty && $v.form.dcw_additional_supply.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWAdditionalSupplyErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="care-refusal-group"
                            label="Is the consumer refusing to follow plan of care?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="care_refusal_yes"
                              name="care_refusal"
                              value="1"
                              v-model.trim="$v.form.care_refusal.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="care_refusal_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="care_refusal_no"
                              name="care_refusal"
                              value="0"
                              v-model.trim="$v.form.care_refusal.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="care_refusal_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="care_refusal_others"
                            name="care_refusal"
                            value="2"
                            v-model.trim="$v.form.care_refusal.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="care_refusal_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="care_refusal_other"
                                autofocus
                                v-if="$v.form.care_refusal.$model === '2'"
                                v-model.trim="$v.form.care_refusal_other.$model"
                                :class="{ 'has-error': $v.form.care_refusal_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.care_refusal.$dirty && $v.form.care_refusal.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getCareRefusalErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Is the Consumer requesting tasks to be performed that aren't on the care plan?"
                          class="required-input label-header"
                      >
                          <b-form-radio
                            id="consumer_task_request_yes"
                            name="consumer_task_request"
                            value="1"
                            v-model.trim="$v.form.consumer_task_request.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="consumer_task_request_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="consumer_task_request_no"
                            name="consumer_task_request"
                            value="0"
                            v-model.trim="$v.form.consumer_task_request.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="consumer_task_request_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.consumer_task_request.$dirty && $v.form.consumer_task_request.$error"
                            class="input-error col col-12"
                          >
                          {{ getConsumerTaskRequestErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.consumer_task_request && $v.form.consumer_task_request.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="What are they requesting?"
                            class="required-input label-header"
                            label-for="consumer_task_request_notes"
                        >
                          <b-form-input
                            id="consumer_task_request_notes"
                            v-model.trim="$v.form.consumer_task_request_notes.$model"
                            :class="{ 'has-error': $v.form.consumer_task_request_notes.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.consumer_task_request_notes.$dirty && $v.form.consumer_task_request_notes.$error"
                            class="input-error"
                          >
                          {{ getConsumerTaskRequestNotesErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-infection-knowledge-group"
                            label="Does the DCW know what signs and symptoms of infection are?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_infection_knowledge_yes"
                              name="dcw_infection_knowledge"
                              value="1"
                              v-model.trim="$v.form.dcw_infection_knowledge.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_infection_knowledge_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_infection_knowledge_no"
                              name="dcw_infection_knowledge"
                              value="0"
                              v-model.trim="$v.form.dcw_infection_knowledge.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_infection_knowledge_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_infection_knowledge_others"
                            name="dcw_infection_knowledge"
                            value="2"
                            v-model.trim="$v.form.dcw_infection_knowledge.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_infection_knowledge_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_infection_knowledge_other"
                                autofocus
                                v-if="$v.form.dcw_infection_knowledge.$model === '2'"
                                v-model.trim="$v.form.dcw_infection_knowledge_other.$model"
                                :class="{ 'has-error': $v.form.dcw_infection_knowledge_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_infection_knowledge.$dirty && $v.form.dcw_infection_knowledge.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWInfectionKnowledgeErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Does the DCW know how to report an incident?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="dcw_report_incident_yes"
                            name="dcw_report_incident"
                            value="1"
                            v-model.trim="$v.form.dcw_report_incident.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_report_incident_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="dcw_report_incident_no"
                            name="dcw_report_incident"
                            value="0"
                            v-model.trim="$v.form.dcw_report_incident.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_report_incident_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.dcw_report_incident.$dirty && $v.form.dcw_report_incident.$error"
                            class="input-error col col-12"
                          >
                          {{ getDCWReportIncidentErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            id="dcw-has-flushot-group"
                            label="Has the DCW gotten a flu shot?"
                            class="required-input label-header mb-0"
                        >
                            <b-form-radio
                              id="dcw_has_flushot_yes"
                              name="dcw_has_flushot"
                              value="1"
                              v-model.trim="$v.form.dcw_has_flushot.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_has_flushot_yes">
                              <span class="option-label">Yes</span>
                            </label>
                            <b-form-radio
                              id="dcw_has_flushot_no"
                              name="dcw_has_flushot"
                              value="0"
                              v-model.trim="$v.form.dcw_has_flushot.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_has_flushot_no">
                              <span class="option-label">No</span>
                            </label>
                            <b-form-radio
                            id="dcw_has_flushot_others"
                            name="dcw_has_flushot"
                            value="2"
                            v-model.trim="$v.form.dcw_has_flushot.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_has_flushot_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_has_flushot_other"
                                autofocus
                                v-if="$v.form.dcw_has_flushot.$model === '2'"
                                v-model.trim="$v.form.dcw_has_flushot_other.$model"
                                :class="{ 'has-error': $v.form.dcw_has_flushot_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_has_flushot.$dirty && $v.form.dcw_has_flushot.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWHasFlushotErrorMessage }}
                            </div>
                        </b-form-group>
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Is the DCW considering getting a flu shot?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="dcw_get_flushot_yes"
                            name="dcw_get_flushot"
                            value="1"
                            v-model.trim="$v.form.dcw_get_flushot.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_get_flushot_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="dcw_get_flushot_no"
                            name="dcw_get_flushot"
                            value="0"
                            v-model.trim="$v.form.dcw_get_flushot.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="dcw_get_flushot_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.dcw_get_flushot.$dirty && $v.form.dcw_get_flushot.$error"
                            class="input-error col col-12"
                          >
                          {{ getDCWGetFlushotErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.dcw_get_flushot && $v.form.dcw_get_flushot.$model === '0'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="What is the reason?"
                            class="required-input label-header"
                        >
                          <b-form-radio
                              id="dcw_get_flushot_reason_allergy"
                              name="dcw_get_flushot_reason"
                              value="1"
                              v-model.trim="$v.form.dcw_get_flushot_reason.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_get_flushot_reason_allergy">
                              <span class="option-label">Allergy</span>
                            </label>
                            <b-form-radio
                              id="dcw_get_flushot_reason_safety"
                              name="dcw_get_flushot_reason"
                              value="2"
                              v-model.trim="$v.form.dcw_get_flushot_reason.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_get_flushot_reason_safety">
                              <span class="option-label">Safety Concerns</span>
                            </label>
                            <b-form-radio
                              id="dcw_get_flushot_reason_unknown"
                              name="dcw_get_flushot_reason"
                              value="3"
                              v-model.trim="$v.form.dcw_get_flushot_reason.$model"
                              :disabled="isSubmitting"
                              class="d-inline ml-3"
                            />
                            <label for="dcw_get_flushot_reason_unknown">
                              <span class="option-label">Unknown</span>
                            </label>
                            <b-form-radio
                            id="dcw_get_flushot_reason_others"
                            name="dcw_get_flushot_reason"
                            value="4"
                            v-model.trim="$v.form.dcw_get_flushot_reason.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                            <label for="dcw_get_flushot_reason_others">
                              <span class="option-label">Other</span>
                            </label>
                              <b-form-input
                                id="dcw_get_flushot_reason_other"
                                autofocus
                                v-if="$v.form.dcw_get_flushot_reason.$model === '4'"
                                v-model.trim="$v.form.dcw_get_flushot_reason_other.$model"
                                :class="{ 'has-error': $v.form.dcw_get_flushot_reason_other.$error, 'col col-7 ml-2 d-inline mb-3': true }"
                                :disabled="isSubmitting"
                                placeholder=""
                                autocomplete="new-password"
                              />
                            <div
                              v-if="$v.form.dcw_get_flushot_reason.$dirty && $v.form.dcw_get_flushot_reason.$error"
                              class="input-error col col-12 mb-3"
                            >
                            {{ getDCWGetFlushotReasonErrorMessage }}
                            </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                    </div>
                    <div class="col col-12" v-if="step === 7">
                        <b-form-group
                          label="Have you had any recent concerns with our service?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="service_has_concerns_yes"
                            name="service_has_concerns"
                            value="1"
                            v-model.trim="$v.form.service_has_concerns.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="service_has_concerns_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="service_has_concerns_no"
                            name="service_has_concerns"
                            value="0"
                            v-model.trim="$v.form.service_has_concerns.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="service_has_concerns_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.service_has_concerns.$dirty && $v.form.service_has_concerns.$error"
                            class="input-error col col-12"
                          >
                          {{ getServiceHasConcernsErrorMessage }}
                          </div>
                      </b-form-group>
                      <template v-if="$v.form.service_has_concerns && $v.form.service_has_concerns.$model === '1'">
                        <div class="clearfix">&nbsp;</div>
                        <b-form-group
                          label="Were your concerns addressed?"
                          class="required-input label-header mt-4"
                      >
                          <b-form-radio
                            id="service_concerns_addressed_yes"
                            name="service_concerns_addressed"
                            value="1"
                            v-model.trim="$v.form.service_concerns_addressed.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="service_concerns_addressed_yes">
                            <span class="option-label">Yes</span>
                          </label>
                          <b-form-radio
                            id="service_concerns_addressed_no"
                            name="service_concerns_addressed"
                            value="0"
                            v-model.trim="$v.form.service_concerns_addressed.$model"
                            :disabled="isSubmitting"
                            class="d-inline ml-3"
                          />
                          <label for="service_concerns_addressed_no">
                            <span class="option-label">No</span>
                          </label>
                          <div
                            v-if="$v.form.service_concerns_addressed.$dirty && $v.form.service_concerns_addressed.$error"
                            class="input-error col col-12"
                          >
                          {{ getServiceConcernsAddressedErrorMessage }}
                          </div>
                      </b-form-group>
                      <div class="clearfix">&nbsp;</div>
                        <b-form-group
                            label="What was the concern?"
                            class="required-input label-header mb-5"
                            label-for="service_concerns"
                        >
                          <b-form-input
                            id="service_concerns"
                            v-model.trim="$v.form.service_concerns.$model"
                            :class="{ 'has-error': $v.form.service_concerns.$error, 'col-10': true }"
                            :disabled="isSubmitting"
                            placeholder="Your answer"
                            autocomplete="new-password"
                          />
                          <div
                            v-if="$v.form.service_concerns.$dirty && $v.form.service_concerns.$error"
                            class="input-error"
                          >
                          {{ getServiceConcernsErrorMessage }}
                          </div>
                        </b-form-group>
                      </template>
                      <div class="clearfix">&nbsp;</div>
                      <div class="col col-12 p-0 service_ratings">
                        <div class="row m-0 mb-2">
                          <div class="col col-4">&nbsp;</div>
                          <div class="col col-2 text-center bold" v-for="(rating_option, ridx) in service_ratings_options" :key="'rating_' + ridx">
                            {{ rating_option }}
                          </div>
                        </div>
                        <div class="row m-0 p-2 bg-light mb-1" v-for="(rating, idx) in service_ratings" :key="'rating_' + idx">
                          <div class="col col-4 pl-0 pb-3 bold">
                            {{ rating }}
                          </div>
                          <div class="col col-2 text-center pt-2" v-for="(rating_option, ridx) in service_ratings_options" :key="'rating_' + ridx">
                            <b-form-radio
                              :name="'rating_options_' + idx"
                              v-if="$v.form[`ratings_options_${idx}`]"
                              :value="ridx + 1"
                              v-model.trim="$v.form[`ratings_options_${idx}`].$model"
                              :disabled="isSubmitting"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="clearfix">&nbsp;</div>
                      <div class="col col-12 p-0">
                          <div class="col col-12 pl-0 pb-3 mt-5 bold">
                            Additional Comments
                          </div>
                          <div class="col col-12 pl-0 pr-0">
                            <textarea
                              type="text"
                              id="additional_comments"
                              name="additional_comments"
                              value=""
                              rows="7"
                              :disabled="isSubmitting"
                              placeholder="enter additional comments (optional)"
                              :class="{ 'form-control': true, 'has-error': $v.form.additional_comments.$error }"
                              v-model.trim="$v.form.additional_comments.$model"
                            />
                          </div>
                      </div>
                      <div class="clearfix">&nbsp;</div>
                    </div>
                  </div>
                    <div class="card-footer text-right" v-if="step !== 0">
                      <button v-if="step > 1" class="btn btn-secondary float-left" type="button" @click="stepBack">Back</button>
                      <button v-if="step > 1" class="btn btn-info mr-5" type="button" @click="saveProgress">Save for Later</button>
                      <button class="btn btn-success" type="submit">
                        <span v-if="step === maxSteps">Submit</span>
                        <span v-else>Next</span>
                      </button>
                    </div>
                  </b-form>
              </template>
            </template>
          </template>
      </div>
	  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import axios from 'axios';

import {
  MSG_GENERIC_ERROR,
  CARE_TYPES,
  SERVICE_RATINGS,
  SERVICE_RATINGS_OPTIONS,
  SELECT_LOCATION_OPTION_TEXT,
  MSG_NOT_FOUND_ERROR,
} from '../config/constants';

const validatePhoneNumber = (value) => {
    if (!value) return false;

    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
};

export default {
  name: 'Wizard',
  props: {},
  mounted() {
    this.loadLocations();
  },
  data() {
    const { token } = this.$route.query;
    const appName = process.env.VUE_APP_NAME || 'Participant Satisfaction Survey';
    // const caresArr = [];

    const form = this.initializeEmptyForm();

    // dates
    const now = new Date();
    const todayDate = new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()));

    return {
      step: 0,
      isSubmitting: false,
      appName,
      // caresArr,
      form,
      hasError: false,
      hasPermissionError: false,
      allDone: false,
      formMessage: null,
      fileUrl: null,
      noRetry: false,
      maxSteps: 7,
      todayDate,
      service_ratings: SERVICE_RATINGS,
      service_ratings_options: SERVICE_RATINGS_OPTIONS,
      locationOptions: [],
      clientOptions: [],
      locationsList: [],
      clientsList: [],
      serverURL: process.env.VUE_APP_SERVER_URL,
      apiToken: token,
      isSaved: false,
      formError: false,
      continueSaved: false,
      formId: null,
      record: null,
      apiURL: process.env.VUE_APP_PORTAL_API_URL,
    }
  },
  validations() {
    if (this.step === 1) {
      return {
        form: {
          location_id: {
            required,
          },
          client_id: {
            required,
          },
          client_number: {
            required,
            isValidPhoneNumber: validatePhoneNumber,
          },
          supervisor_name: {
            required,
          },
        }
      }
    } else if (this.step === 2) {
      const form = {};

      if (this.caresArr && this.caresArr.length) {
        const totalTypes = this.caresArr.length;

        for(let i = 0; i < totalTypes; i += 1) {
          if (this.caresArr[i]) {
            Object.assign(form, { [`aide_care_${this.caresArr[i].id}`]: {}, [`task_care_${this.caresArr[i].id}`]: {} });
          }
        }
      }

      Object.assign(form, {
        service_match: {
          required,
        },
        binder_available: {
          required,
        },
        dcw: {
          required,
        },
        care_plan: {
          required,
        },
        dcw_notes: {
          // required,
        },
        care_plan_notes: {
          // required,
        },
        care_others: {
          // required,
        },
      });

      return {
        form,
      }
    } else if (this.step === 3) {
      const form = {};

      Object.assign(form, {
        health_changes: {
          required,
        },
        date_last_appointment: {
          required: requiredIf(function(form) {
              return !form.date_last_appointment_unknown;
          }),
        },
        date_next_appointment: {
          required: requiredIf(function(form) {
              return !form.date_next_appointment_unknown;
          }),
        },
        revisit_hospital: {
          // required,
        },
        date_last_appointment_unknown: {
          // required,
        },
        date_next_appointment_unknown: {
          // required,
        },
        revisit_uti: {
          // required,
        },
        revisit_uri: {
          // required,
        },
        revisit_pneumonia: {
          // required,
        },
        revisit_covid: {
          // required,
        },
        revisit_other: {
          // required,
        },
        revisit_other_notes: {
          // required,
        },
        revisit_reasons: {
          // required,
        },
        revisit_none: {
          // required,
        },
        revisit_fallen: {
          required,
        },
        revisit_fallen_notes: {
          // required,
        },
        medication_self_setup: {
          required,
        },
        medication_self_setup_notes: {
          // required,
        },
        medication_helpline: {
          required,
        },
        medication_helpline_other: {
          // required,
        },
        aide_no_med_admin: {
          required,
        },
        aide_reminder: {
          required,
        },
        medication_changes: {
          required,
        },
        medication_changes_notes: {
          // required,
        },
        weight_change: {
          required,
        },
        weight_change_reason: {
          // required,
        },
        flushot_got: {
          required,
        },
        flushot_decision: {
          required,
        },
        fever: {
          required,
        },
        shortness_of_breath: {
          required,
        },
        dry_cough: {
          required,
        },
        covid_contact: {
          required,
        },
        covid_contact_date: {
          // required,
        },
      });

      return {
        form,
      }
    } else if (this.step === 4) {
      const form = {};

      Object.assign(form, {
        living_situation: {
          required,
        },
        living_situation_other: {
          // required,
        },
        living_situation_notes: {
          // required,
        },
        address_change: {
          required,
        },
        new_address: {
          // required,
        },
        children_in_home: {
          required,
        },
        children_under_18: {
          required: requiredIf(function(form) {
              return form.children_in_home && form.children_in_home !== '0';
          }),
        },
        children_in_home_service_hours: {
          required: requiredIf(function(form) {
              return form.children_under_18 && form.children_under_18 === '1';
          }),
        },
        child_abuse_clearance: {
          required: requiredIf(function(form) {
              return form.children_in_home_service_hours && form.children_in_home_service_hours === '1';
          }),
        },
        home_safety_changes: {
          required,
        },
        home_safety_changes_notes: {
          // required,
        },
        active_community_agencies: {
          required,
        },
        active_community_agencies_notes: {
          required: requiredIf(function(form) {
              return form.active_community_agencies && form.active_community_agencies === '1';
          }),
        },
        emergency_numbers: {
          required,
        },
        emergency_system: {
          required,
        },
        backup_caregiver: {
          required,
        },
        backup_caregiver_phone: {
          required,
        },
        priority_status: {
          required,
        },
        special_care_needs: {
          // required,
        },
        anti_bacteria_soap: {
          required,
        },
        gloves_supply: {
          required,
        },
      });

      return {
        form,
      }
    } else if (this.step === 5) {
      const form = {};

      Object.assign(form, {
        caregivers_gloves: {
          required,
        },
        caregivers_mask: {
          required,
        },
        caregivers_wash_hands: {
          required,
        },
        caregivers_social_distancing: {
          required,
        },
        caregivers_fevers: {
          required,
        },
        caregivers_shortness_of_breath: {
          required,
        },
        caregivers_dry_cough: {
          required,
        },
        caregivers_covid_contact: {
          required,
        },
        caregivers_covid_contact_other: {
          // required,
        },
      });

      return {
        form,
      }
    } else if (this.step === 6) {
      const form = {};

      Object.assign(form, {
        date_last_aide: {
          required,
        },
        aide_on_time: {
          required,
        },
        dcw_per_org_policy: {
          required,
        },
        dcw_per_org_policy_other: {
          // required,
        },
        dcw_tasks_match_plan: {
          required,
        },
        dcw_tasks_match_plan_other: {
          // required,
        },
        dcw_instructions: {
          required,
        },
        dcw_instructions_other: {
          // required,
        },
        caregiver_plan_changes: {
          required,
        },
        caregiver_plan_changes_other: {
          // required,
        },
        competency_completed: {
          required,
        },
        competency_completed_other: {
          // required,
        },
        dcw_demonstrate_supply: {
          required,
        },
        dcw_demonstrate_supply_other: {
          // required,
        },
        dcw_additional_supply: {
          required,
        },
        dcw_additional_supply_other: {
          // required,
        },
        care_refusal: {
          required,
        },
        care_refusal_other: {
          // required,
        },
        consumer_task_request: {
          required,
        },
        consumer_task_request_notes: {
          // required,
        },
        dcw_infection_knowledge: {
          required,
        },
        dcw_infection_knowledge_other: {
          // required,
        },
        dcw_report_incident: {
          required,
        },
        dcw_has_flushot: {
          required,
        },
        dcw_has_flushot_other: {
          // required,
        },
        dcw_get_flushot: {
          required,
        },
        dcw_get_flushot_reason: {
          // required,
        },
        dcw_get_flushot_reason_other: {
          // required,
        },
      });

      return {
        form,
      }
    } else if (this.step === 7) {
      const form = {};

      Object.assign(form, {
        service_has_concerns: {
          required,
        },
        service_concerns_addressed: {
          // required,
        },
        service_concerns: {
          // required,
        },
        additional_comments: {
          // required,
        },
      });

      if (this.service_ratings && this.service_ratings.length) {
        const totalRatings = this.service_ratings.length;
        const totalRatingsOptions = this.service_ratings_options.length;

        for (let i = 0; i < totalRatings; i += 1) {
          for (let j = 0; j < totalRatingsOptions; j += 1) {
            Object.assign(form, {[`ratings_options_${i}`] : { required } });
          }
        }
      }

      return {
        form,
      }
    }
	},
  computed: {
    getLocationErrorMessage: function() {
			return this.getErrorMessage('location_id');
		},
    getClientIDErrorMessage: function() {
			return this.getErrorMessage('client_id');
		},
    getSupervisorNameErrorMessage: function() {
			return this.getErrorMessage('supervisor_name');
		},
    getClientPhoneErrorMessage: function() {
			return this.getErrorMessage('client_number');
		},
    getServiceMatchErrorMessage: function() {
			return this.getErrorMessage('service_match');
		},
    getBinderErrorMessage: function() {
			return this.getErrorMessage('binder_available');
		},
    getDCWErrorMessage: function() {
			return this.getErrorMessage('dcw');
		},
    getCarePlanErrorMessage: function() {
			return this.getErrorMessage('care_plan');
		},
    getHealthChangesErrorMessage: function() {
			return this.getErrorMessage('health_changes');
		},
    getLastAppointmentDateErrorMessage: function() {
			return this.getErrorMessage('date_last_appointment');
		},
    getNextAppointmentDateErrorMessage: function() {
			return this.getErrorMessage('date_next_appointment');
		},
    getRevisitReasonsErrorMessage: function() {
			return this.getErrorMessage('revisit_reasons');
		},
    getRevisitFallenErrorMessage: function() {
			return this.getErrorMessage('revisit_fallen');
		},
    getRevisitFallenNotesErrorMessage: function() {
			return this.getErrorMessage('revisit_fallen_notes');
		},
    getMedicationSelfSetupErrorMessage: function() {
			return this.getErrorMessage('medication_self_setup');
		},
    getMedicationSelfSetupNotesErrorMessage: function() {
			return this.getErrorMessage('medication_self_setup_notes');
		},
    getMedicationHelplineErrorMessage: function() {
			return this.getErrorMessage('medication_helpline');
		},
    getAideNoMedsAdminErrorMessage: function() {
			return this.getErrorMessage('aide_no_med_admin');
		},
    getAideReminderErrorMessage: function() {
			return this.getErrorMessage('aide_reminder');
		},
    getMedicationChangesErrorMessage: function() {
			return this.getErrorMessage('medication_changes');
		},
    getMedicationChangesNotesErrorMessage: function() {
			return this.getErrorMessage('medication_changes_notes');
		},
    getWeightChangeErrorMessage: function() {
			return this.getErrorMessage('weight_change');
		},
    getWeightChangeReasonErrorMessage: function() {
			return this.getErrorMessage('weight_change_reason');
		},
    getFlushotGotErrorMessage: function() {
			return this.getErrorMessage('flushot_got');
		},
    getFlushotDecisionErrorMessage: function() {
			return this.getErrorMessage('flushot_decision');
		},
    getFeverErrorMessage: function() {
			return this.getErrorMessage('fever');
		},
    getShortnessOfBreathErrorMessage: function() {
			return this.getErrorMessage('shortness_of_breath');
		},
    getDryCoughErrorMessage: function() {
			return this.getErrorMessage('dry_cough');
		},
    getCovidContactErrorMessage: function() {
			return this.getErrorMessage('covid_contact');
		},
    getCovidContactDateErrorMessage: function() {
			return this.getErrorMessage('covid_contact_date');
		},
    getLivingSituationErrorMessage: function() {
			return this.getErrorMessage('living_situation');
		},
    getLivingSituationNotesErrorMessage: function() {
			return this.getErrorMessage('living_situation_notes');
		},
    getAddressChangeErrorMessage: function() {
			return this.getErrorMessage('address_change');
		},
    getNewAddressErrorMessage: function() {
			return this.getErrorMessage('new_address');
		},
    getChildrenInHomeErrorMessage: function() {
			return this.getErrorMessage('children_in_home');
		},
    getChildrenUnder18ErrorMessage: function() {
			return this.getErrorMessage('children_under_18');
		},
    getChildrenServiceHoursErrorMessage: function() {
			return this.getErrorMessage('children_in_home_service_hours');
		},
    getChildAbuseClearanceErrorMessage: function() {
			return this.getErrorMessage('child_abuse_clearance');
		},
    getHomeSafetyChangesErrorMessage: function() {
			return this.getErrorMessage('home_safety_changes');
		},
    getActiveCommunityAgenciesErrorMessage: function() {
			return this.getErrorMessage('active_community_agencies');
		},
    getActiveCommunityAgenciesNotesErrorMessage: function() {
			return this.getErrorMessage('active_community_agencies_notes');
		},
    getEmergencyNumbersErrorMessage: function() {
			return this.getErrorMessage('emergency_numbers');
		},
    getEmergencySystemErrorMessage: function() {
			return this.getErrorMessage('emergency_system');
		},
    getBackupCaregiverErrorMessage: function() {
			return this.getErrorMessage('backup_caregiver');
		},
    getBackupCaregiverPhoneErrorMessage: function() {
			return this.getErrorMessage('backup_caregiver_phone');
		},
    getPriorityStatusErrorMessage: function() {
			return this.getErrorMessage('priority_status');
		},
    getSpecialCareNeedsErrorMessage: function() {
			return this.getErrorMessage('special_care_needs');
		},
    getAntiBacterialSoapErrorMessage: function() {
			return this.getErrorMessage('anti_bacteria_soap');
		},
    getGlovesSupplyErrorMessage: function() {
			return this.getErrorMessage('gloves_supply');
		},
    getCaregiversGlovesErrorMessage: function() {
			return this.getErrorMessage('caregivers_gloves');
		},
    getCaregiversMaskErrorMessage: function() {
			return this.getErrorMessage('caregivers_mask');
		},
    getCaregiversWashHandsErrorMessage: function() {
			return this.getErrorMessage('caregivers_wash_hands');
		},
    getCaregiversSocialDistancingErrorMessage: function() {
			return this.getErrorMessage('caregivers_social_distancing');
		},
    getCaregiversFeversErrorMessage: function() {
			return this.getErrorMessage('caregivers_fevers');
		},
    getCaregiversShortnessOfBreathErrorMessage: function() {
			return this.getErrorMessage('caregivers_shortness_of_breath');
		},
    getCaregiversDryCoughErrorMessage: function() {
			return this.getErrorMessage('caregivers_dry_cough');
		},
    getCaregiversCovidContactErrorMessage: function() {
			return this.getErrorMessage('caregivers_covid_contact');
		},
    getLastAideDateErrorMessage: function() {
			return this.getErrorMessage('date_last_aide');
		},
    getAideOnTimeErrorMessage: function() {
			return this.getErrorMessage('aide_on_time');
		},
    getDCWPerOrgPolicyErrorMessage: function() {
			return this.getErrorMessage('dcw_per_org_policy');
		},
    getDCWTasksMatchPlanErrorMessage: function() {
			return this.getErrorMessage('dcw_tasks_match_plan');
		},
    getDCWInstructionsErrorMessage: function() {
			return this.getErrorMessage('dcw_tasks_match_plan');
		},
    getCaregiverPlanChangesErrorMessage: function() {
			return this.getErrorMessage('caregiver_plan_changes');
		},
    getCompetencyCompletedErrorMessage: function() {
			return this.getErrorMessage('competency_completed');
		},
    getDCWDemonstrateSupplyErrorMessage: function() {
			return this.getErrorMessage('dcw_demonstrate_supply');
		},
    getDCWAdditionalSupplyErrorMessage: function() {
			return this.getErrorMessage('dcw_additional_supply');
		},
    getCareRefusalErrorMessage: function() {
			return this.getErrorMessage('care_refusal');
		},
    getConsumerTaskRequestErrorMessage: function() {
			return this.getErrorMessage('consumer_task_request');
		},
    getConsumerTaskRequestNotesErrorMessage: function() {
			return this.getErrorMessage('consumer_task_request_notes');
		},
    getDCWInfectionKnowledgeErrorMessage: function() {
			return this.getErrorMessage('dcw_infection_knowledge');
		},
    getDCWReportIncidentErrorMessage: function() {
			return this.getErrorMessage('dcw_report_incident');
		},
    getDCWHasFlushotErrorMessage: function() {
			return this.getErrorMessage('dcw_has_flushot');
		},
    getDCWGetFlushotErrorMessage: function() {
			return this.getErrorMessage('dcw_get_flushot');
		},
    getDCWGetFlushotReasonErrorMessage: function() {
			return this.getErrorMessage('dcw_get_flushot_reason');
		},
    getServiceHasConcernsErrorMessage: function() {
			return this.getErrorMessage('service_has_concerns');
		},
    getServiceConcernsAddressedErrorMessage: function() {
			return this.getErrorMessage('service_concerns_addressed');
		},
    getServiceConcernsErrorMessage: function() {
			return this.getErrorMessage('service_concerns');
		},
  },
  methods: {
		getErrorMessage(field) {
      if (field === 'client_number') {
        // validate phone number
        if (this.$v.form.client_number.required && !this.$v.form.client_number.isValidPhoneNumber) {
          return 'Please enter a valid phone number!';
        }
      }

			return 'Required!';
    },
    acceptNumber(value) {
      const x = this.form.client_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  
      this.form.client_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    async saveSurvey(e) {
      if (e) e.preventDefault();

      this.$v.$touch();
  
      if (this.$v.$invalid) {
        return false;
      }

      if (this.step > this.maxSteps) return false;

      if (this.step < this.maxSteps) {
        this.$v.$reset();
        
        this.step++;

        window.scrollTo(0, 0);
      } else {
        const loader = this.$loading.show();
        const data = this.form;

        // location
        if (this.locationsList && this.locationsList.length) {
          const location = this.locationsList.find((itm) => itm.id === data.location_id);

          if (location) Object.assign(data, { location });
        }

        // client
        if (this.clientsList && this.clientsList.length) {
          const client = this.clientsList.find((itm) => itm.id === data.client_id);

          if (client) Object.assign(data, { client });
        }
        
        Object.assign(data, { responses: this.responses, token: this.apiToken, step: this.step });

        if (this.formId) Object.assign(data, { shortId: this.formId });

        return axios.post(this.serverURL, data).then((result) => {
          this.hasError = false;
          this.hasPermissionError = false;
          this.formMessage = null;

          // if (result && result.data && result.data.file) this.fileUrl = result.data.file;

          this.allDone = true;
        }).catch((err) => {
          this.hasError = true;
          this.noRetry = false;

          if (err && err.status && err.status === 401) {
            this.noRetry = true;
          }

          const errMsg = (err && err.data && err.data.message) ? err.data.message : MSG_GENERIC_ERROR;
          
          this.formMessage = errMsg;
          this.allDone = false;
          this.fileUrl = null;

          if (err && err.data && err.data.shortId) {
            this.isSaved = true;
            this.formId = err.data.shortId;
          }

          // this.submissionErrorCount++;
        }).finally((err) => {
          this.isSubmitting = false;

          loader.hide();
        });
      }

      return false;

    },
    async retrySubmission(e) {
      if (this.step < this.maxSteps) {
        await this.saveProgress(e, true);
        return;
      }

      this.saveSurvey();
    },
    restartSubmission() {
      this.form = this.initializeEmptyForm();
      this.$v.$reset();
      this.$v.form.$reset();

      this.formMessage = null;

      const caller = this;

      setTimeout(() => {
        caller.allDone = false;
        caller.fileUrl = null;

        this.step = 1;
      }, 2000); // 2 seconds
    },
    stepBack() {
      if (this.step > 1) {
        this.step--;

        window.scrollTo(0, 0);       
      }
    },
    startNew() {
      this.formId = null;
      this.isSaved = false;

      this.form = this.initializeEmptyForm();

      this.formMessage = null;
      this.allDone = false;
      this.fileUrl = null;

      setTimeout(() => {
        this.step = 1;
      }, 1000); // 1 seconds
    },
    continueAssessment() {
      this.continueSaved = true;
    },
    cancelContinue() {
      this.continueSaved = false;
      this.record = null;
      this.isSaved = false;
      this.formId = null;
      this.step = 0;
    },
    async findAssessment(e) {
      if (e) e.preventDefault();

      if (!this.formId) {
        return;
      } else {
        const loader = this.$loading.show();

        this.isSubmitting = true;
        this.hasError = false;
        this.formError = false;
        this.hasPermissionError = false;
        this.formMessage = null;

        await axios.get(`${this.serverURL}/forms/${this.formId}`).then((result) => {
          if (result && result.data) {
            this.record = result.data;
          }
          // this.step = 
        }).catch((err) => {
          this.formError = true;

          let errMsg = (err && err.data && err.data.message) ? err.data.message : MSG_GENERIC_ERROR;

          if (err && (!err.data || !err.data.message) && (err.status && err.status === 404)) {
            errMsg = MSG_NOT_FOUND_ERROR;
          }
          
          this.formMessage = errMsg;
        }).finally((err) => {
          this.isSubmitting = false;

          loader.hide();
        });
      }
    },
    initializeEmptyForm() {
      const caresArr = [];

      const form = {
        location_id: '',        
        client_id: '',
        client_number: '',
        supervisor_name: '',
        service_match: '',
        binder_available: '',
        dcw: '',
        care_plan: '',
        dcw_notes: '',
        care_plan_notes: '',
        health_changes: '',
        date_last_appointment: '',
        date_next_appointment: '',
        date_last_appointment_unknown: '',
        date_next_appointment_unknown: '',
        revisit_hospital: '',
        revisit_uti: '',
        revisit_uri: '',
        revisit_pneumonia: '',
        revisit_covid: '',
        revisit_other: '',
        revisit_other_notes: '',
        revisit_reasons: '',
        revisit_none: '',
        revisit_fallen: '',
        revisit_fallen_notes: '',
        medication_self_setup: '',
        medication_self_setup_notes: '',
        medication_helpline: '',
        medication_helpline_other: '',
        aide_no_med_admin: '',
        aide_reminder: '',
        medication_changes: '',
        medication_changes_notes: '',
        weight_change: '',
        weight_change_reason: '',
        flushot_got: '',
        flushot_decision: '',
        fever: '',
        shortness_of_breath: '',
        dry_cough: '',
        covid_contact: '',
        covid_contact_date: '',
        living_situation: '',
        living_situation_other: '',
        living_situation_notes: '',
        address_change: '',
        new_address: '',
        children_in_home: '',
        children_under_18: '',
        children_in_home_service_hours: '',
        child_abuse_clearance: '',
        home_safety_changes: '',
        home_safety_changes_notes: '',
        active_community_agencies: '',
        active_community_agencies_notes: '',
        emergency_numbers: '',
        emergency_system: '',
        backup_caregiver: '',
        backup_caregiver_phone: '',
        priority_status: '',
        special_care_needs: '',
        anti_bacteria_soap: '',
        gloves_supply: '',
        caregivers_gloves: '',
        caregivers_mask: '',
        caregivers_wash_hands: '',
        caregivers_social_distancing: '',
        caregivers_fevers: '',
        caregivers_shortness_of_breath: '',
        caregivers_dry_cough: '',
        caregivers_covid_contact: '',
        caregivers_covid_contact_other: '',
        date_last_aide: '',
        aide_on_time: '',
        dcw_per_org_policy: '',
        dcw_per_org_policy_other: '',
        dcw_tasks_match_plan: '',
        dcw_tasks_match_plan_other: '',
        dcw_instructions: '',
        dcw_instructions_other: '',
        caregiver_plan_changes: '',
        caregiver_plan_changes_other: '',
        competency_completed: '',
        competency_completed_other: '',
        dcw_demonstrate_supply: '',
        dcw_demonstrate_supply_other: '',
        dcw_additional_supply: '',
        dcw_additional_supply_other: '',
        care_refusal: '',
        care_refusal_other: '',
        consumer_task_request: '',
        consumer_task_request_notes: '',
        dcw_infection_knowledge: '',
        dcw_infection_knowledge_other: '',
        dcw_report_incident: '',
        dcw_has_flushot: '',
        dcw_has_flushot_other: '',
        dcw_get_flushot: '',
        dcw_get_flushot_reason: '',
        dcw_get_flushot_reason_other: '',
        service_has_concerns: '',
        service_concerns: '',
        service_concerns_addressed: '',
        care_others: '',
        additional_comments: '',
    };

      if (CARE_TYPES && CARE_TYPES.length) {
        const totalTypes = CARE_TYPES.length;

        for(let i = 0; i < totalTypes; i += 1) {
          if (CARE_TYPES[i]) {
            const cleanId = CARE_TYPES[i].toLowerCase().replace(/ /g, '_').replace(/\//g, '_');

            caresArr.push({
              id: cleanId,
              label: CARE_TYPES[i],
            });

            Object.assign(form, { [`aide_care_${cleanId}`]: 0, [`task_care_${cleanId}`]: 0 });
          }
        }
      }

      if (this.service_ratings && this.service_ratings.length) {
          const totalRatings = this.service_ratings.length;
          const totalRatingsOptions = this.service_ratings_options.length;

          for (let i = 0; i < totalRatings; i += 1) {
            for (let j = 0; j < totalRatingsOptions; j += 1) {
              Object.assign(form, { [`ratings_options_${i}`] : '' });
            }
          }
      }

      this.caresArr = caresArr;

      this.submissionErrorCount = 0;

      return form;
    },
    async loadLocations() {
      const locationOptions = [{ value: '', text: `- ${SELECT_LOCATION_OPTION_TEXT} -` }];

      if (this.apiURL && this.apiToken) {
        const loader = this.$loading.show();

        await axios.get(`${this.apiURL}/locations/all?api_token=${this.apiToken}`).then((result) => {
          this.hasError = false;
          this.formMessage = null;


          if (result && result.data) {
            result.data.map((loc) => {
              if (loc) {
                locationOptions.push({
                  value: loc.id,
                  text: loc.title,
                });
              }

              return loc;
            });

            this.locationsList = result.data;
          }
        }).catch((err) => {
          this.hasError = true;

          if (err.status && err.status === 401) {
            this.hasPermissionError = true;
            
            this.formMessage = 'We are unable to load this page at the moment, please make sure you are authorized to access this page.';
          } else {
            this.noRetry = true;
            this.formMessage = 'We are unable to load this page at the moment, please try again later.';
          }

          this.allDone = false;
        }).finally((err) => {
          loader.hide();
        });
      }

      this.locationOptions = locationOptions;
    },
    async loadClients(locationId) {
      this.$v.form.client_id.$model = '';
      this.$v.form.client_id.$reset();

      this.clientsList = [];
      this.clientOptions = [];

      if (!locationId) {
        // this.clientOptions = [{ value: '', text: `- ${SELECT_CLIENT_OPTION_TEXT} -` }];

        return;
      }

      await this.fetchClients(locationId);

      this.form.client_id = '';
      this.form.client_number = '';
    },
    async fetchClients(locationId) {
      // const clientOptions = [{ value: '', text: `- ${SELECT_CLIENT_OPTION_TEXT} -` }];
      const clientOptions = [];

      if (this.apiURL && this.apiToken) {
        const loader = this.$loading.show();

        this.clientsList = [];

        await axios.get(`${this.apiURL}/locations/${locationId}/clients?api_token=${this.apiToken}`).then((result) => {
          this.hasError = false;
          this.formMessage = null;

          if (result && result.data) {
            result.data.map((itm) => {
              if (itm) {
                clientOptions.push({
                  value: itm.id,
                  text: itm.name,
                });
              }

              return itm;
            });

            this.clientsList = result.data;
          }
        }).catch((err) => {
          this.hasError = true;

          if (err.message && err.message.indexOf('401') !== -1) {
            this.hasPermissionError = true;
            
            this.formMessage = 'We are unable to load this page at the moment, please make sure you are authorized to access this page.';
          } else {
            this.noRetry = true;
            this.formMessage = 'We are unable to load this page at the moment, please try again later.';
          }

          this.allDone = false;
        }).finally((err) => {
          loader.hide();
        });
      }

      this.clientOptions = clientOptions;
    },
    selectClient(clientId) {
      if (clientId) {
        const client = this.clientsList.find((itm) => itm.id === clientId);

        if (client && client.mobile_number) {
          this.form.client_number = client.mobile_number;
          this.acceptNumber(client.mobile_number);
        }
      }
    },
    async loadAssessment() {
      if (!this.record || !this.record.id) {
        return;
      }

      try {
        await this.fetchClients(this.record.location_id);

        this.selectClient(this.record.client_id);

        this.form = { ...this.form, ...this.record };
        this.step = 1;
      } catch (err) {

      }
    },
    async saveProgress(_, isRetrying = false) {
        let shouldProceed = false;

        if (isRetrying) {
          shouldProceed = true;
        } else {
          await this.$dialog
                .confirm('Are you sure you want to save your progress for later?', {
                    okText: 'Yes',
                    cancelText: 'No',
                })
                .then(() => {
                   shouldProceed = true;
                })
                .catch(() => {});
        }

        if (!shouldProceed) return;

        const loader = this.$loading.show();
        const data = this.form;

        // location
        if (this.locationsList && this.locationsList.length) {
          const location = this.locationsList.find((itm) => itm.id === data.location_id);

          if (location) Object.assign(data, { location });
        }

        // client
        if (this.clientsList && this.clientsList.length) {
          const client = this.clientsList.find((itm) => itm.id === data.client_id);

          if (client) Object.assign(data, { client });
        }

        Object.assign(data, { responses: this.responses, step: this.step, token: this.apiToken });

        if (this.formId) Object.assign(data, { shortId: this.formId });

        await axios.post(this.serverURL, data).then((result) => {
          this.hasError = false;
          this.hasPermissionError = false;
          this.formMessage = null;

          if (result && result.data && result.data.shortId) {
            this.formId = result.data.shortId;
            this.isSaved = true;
          }
        }).catch((err) => {
          this.hasError = true;
          this.noRetry = false;
          this.isSaved = false;
          this.formId = null;

          if (err && err.status && err.status === 401) {
            this.noRetry = true;
          }

          const errMsg = (err && err.data && err.data.message) ? err.data.message : MSG_GENERIC_ERROR;
          
          this.formMessage = errMsg;
          this.allDone = false;
          this.fileUrl = null;

          if (err && err.data && err.data.shortId) {
            this.isSaved = true;
            this.formId = err.data.shortId;
          }
        }).finally((err) => {
          this.isSubmitting = false;

          loader.hide();
        });
    },
    revisitNoneAction(value) {
      if (value === '1') {
        this.form.revisit_hospital = '';
        this.form.revisit_uti = '';
        this.form.revisit_uri = '';
        this.form.revisit_covid = '';
        this.form.revisit_pneumonia = '';
        this.form.revisit_other = '';
        this.form.revisit_other_notes = '';
        this.form.revisit_reasons = '';
      }
    }
  }
}
</script>

<style scoped>
div.service_ratings, div.info-block {
  font-size: 16px;
}
</style>
