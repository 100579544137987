<template>
  <div id="app">
    <div class="container">
      <div class="col col-12 text-center pt-4 pb-4">
        <img alt="Caresify logo" src="./assets/img/logo.png" class="img-fluid" width="180"/>
      </div>
      <!-- <Wizard/> -->
      <main>
        <router-view />
      </main>
      <div class="col col-12 text-center copyright-footer pt-5">
        Copyright &copy; 2020 Caresify LLC. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Wizard from './components/Form.vue';

export default {
  name: 'App',
  components: {
    Wizard
  }
}
</script>

<style>
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
   url(./assets/fonts/Quicksand-Regular.otf);
}

@font-face {
  font-family: "Quicksand-Medium";
  src: local("Quicksand-Medium"),
   url(./assets/fonts/Quicksand-Medium.ttf);
}

@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand-Bold"),
   url(./assets/fonts/Quicksand-Bold.otf);
}

#app {
  font-family: "Quicksand", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  font-size: 1.2em;
  margin-top: 0px;
}

.copyright-footer {
  font-size: 16px;
}

fieldset.form-group span.option-label {
  font-size: 16px;
}

.label-header legend, .label-header label {
  font-weight: bold !important;
  font-size: 16px !important;
}

.label-header .bv-no-focus-ring {
  display: flex;
  flex-wrap: wrap;
}

h3, h5 {
  font-weight: bold;
}

.has-error {
    border: 1px solid #d33c40;
}

.page-error {
  font-size: 22px !important;
}

.input-error {
	color: #d33c40;
  font-size: 16px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.workarea {
  min-height: 200px;
}

.question-check-group {
  font-size: 1.3em;
}

.card-body.card-language {
    min-height: 200px;
}
</style>
