import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Loading from 'vue-loading-overlay';
import VueToastr from 'vue-toastr';
import VueRouter from 'vue-router';
import vSelect from 'vue-select';
import VueSignature from "vue-signature-pad";
import axios from 'axios';
import VuejsDialog from 'vuejs-dialog';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-select/dist/vue-select.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import App from './App.vue';
import Wizard from './components/Form.vue';
import Details from './components/Details.vue';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/:id', component: Details, name: 'Details' },
    { path: '/', component: Wizard, name: 'Wizard' },
  ]
});

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(Loading);
Vue.use(VueSignature);
Vue.use(VueToastr, {
  defaultTimeout: 10000,
  defaultPosition: 'toast-bottom-right',
  defaultProgressBar: false,
});
Vue.use(VuejsDialog);

Vue.component('v-select', vSelect);

axios.interceptors.response.use((resp) => {
  let response = {};
  
	if (!resp || !resp.data) return response;
  
	response = Object.assign(response, { data: resp.data.data ? resp.data.data : resp.data });
	
	if (Array.isArray(response.data)) {
	  response.data = response.data.map((data) => {
		if (data.attributes) {
			data = { ...data, ...data.attributes };
					
			delete data.attributes;
		}

		if (data.relationships) {
			const relationshipKeys = Object.keys(data.relationships);

			if (relationshipKeys.length) {
				relationshipKeys.map((key) => {
					const relatedItem = data.relationships[key];

					if (relatedItem) {
						// console.log('relatedItem', relatedItem, data.included);
					}
				});
			}
			// data = { ...data, ...data.attributes };			
		}
  
		return data;
	  });
	} else {
	  if (response.data.attributes) {
		Object.assign(response, { data: { ...response.data, ...response.data.attributes }});

		delete response.data.attributes;
	  }
	}
	
	if (response.data.relationships) {
	  Object.assign(response, { relationships: response.data.relationships });

	  delete response.data.relationships;
	}
  
	if (resp.data.included) Object.assign(response, { included: resp.data.included });

	// normalize included data
	if (response.included && response.included.length) {
		const includedArr = response.included.map((itm) => {
			if (itm.attributes) {
				Object.assign(itm, { ...itm, ...itm.attributes });

				delete itm.attributes;
			}

			return itm;
		});

		Object.assign(response, { included: includedArr });
	}

	return response;
}, function (error) {
  return Promise.reject(error.response);
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
