<template>
	<div class="col col-12 col-lg-9 mx-auto pl-0 pr-0">
        <div class="col col-12">
          <h3 class="mb-4">{{ appName }} Response</h3>
        </div>

		<div class="card">
          <template v-if="allDone">
            <h4 class="mt-5 pl-3 pr-3">Thank you for signing this form.</h4>
            <div class="col col-7 mb-5 mt-4 mx-auto p-0">
				<div class="card card-body bg-light p-2">Please remember to close this window.</div>
			</div>
          </template>
          <template v-else>
            <template v-if="hasError">
              <div class="card-body workarea input-error page-error">
                {{ formMessage }}
                <div class="col col-12 text-center mt-5">
                  <!-- <button @click="retrySubmission" class="btn btn-secondary btn-lg">Retry</button> -->
                </div>
              </div>
            </template>
            <template v-else>
				<div class="col col-12 pt-4 pb-4 text-left">
					<h4>Please sign below.</h4>
					<div class="signature-container">
						<VueSignaturePad
							id="signature"
							width="100%"
							height="150px"
							ref="signaturePad"
							:options="signatureOptions"
						/>
					</div>
				</div>
                <div class="card-footer text-right">
					<button class="btn btn-dark float-left" v-if="hasSigned" type="button" @click="clearSignature">Clear Signature</button>
                    <button @click="saveSignature" :disabled="!hasSigned" class="btn btn-success" type="button">
                      Submit
                    </button>
                </div>				
			</template>
		  </template>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import {
  MSG_GENERIC_ERROR,
  MSG_NOT_FOUND_PAGE_ERROR,
  CARE_TYPES,
  SERVICE_RATINGS,
  SERVICE_RATINGS_OPTIONS,
} from '../config/constants';

let appName = process.env.VUE_APP_NAME || 'Participant Satisfaction Survey';

export default {
  	name: 'Details',
//   props: {},
	mounted() {
		const responseId = this.$route.params.id;

		this.fetchResponse(responseId);
	},
	data() {
		return {
			isLoading: true,
			hasError: false,
			allDone: false,
			appName,
			formMessage: null,
			hasSigned: false,
			signatureOptions: {
				penColor: "#000",
				onEnd: this.setHasSigned,
			}
		};
	},
	methods: {
		async fetchResponse(id) {
			const loader = this.$loading.show();

			this.formMessage = null;

			await axios.get(`${process.env.VUE_APP_SERVER_URL}/response/${id}`)
			.then((result) => {
				this.record = result && result.data;
			})
			.catch((err) => {
				this.isLoading = false;
				this.hasError = true;

				let errMsg;

				if (err.message && err.message.indexOf('404') !== -1) {
					errMsg = MSG_NOT_FOUND_PAGE_ERROR;
				} else {
					errMsg = (err && err.data && err.data.message) ? err.data.message : MSG_GENERIC_ERROR;
				}

				this.formMessage = errMsg;				
			}).finally(() => {
				loader.hide();
			});
		},
		async saveSignature() {
			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

			if (isEmpty) {
				this.$toastr.e('Signature cannot be empty!');
				
				return;
			}

			const loader = this.$loading.show();

			await axios.post(`${process.env.VUE_APP_SERVER_URL}/response/${this.record.id}`, { data })
			.then((result) => {
				// this.record = result && result.data;
				this.hasError = false;
				this.allDone = true;
			})
			.catch((err) => {
				this.isLoading = false;
				// this.hasError = true;

				let errMsg;

				if (err.message && err.message.indexOf('404') !== -1) {
					errMsg = MSG_NOT_FOUND_PAGE_ERROR;
				} else {
					errMsg = (err && err.data && err.data.message) ? err.data.message : MSG_GENERIC_ERROR;
				}

				// this.formMessage = errMsg;
				this.$toastr.e(errMsg);
			}).finally(() => {
				loader.hide();
			});

		},
		clearSignature() {
			this.$refs.signaturePad.undoSignature();

			this.hasSigned = false;
		},
		setHasSigned() {
			this.hasSigned = true;
		},
	}
}
</script>

<style scoped>
	div.signature-container {
		width: 60%;
		border-bottom: 2px solid #ccc;
	}
</style>