export const MSG_GENERIC_ERROR = 'We are unable to process your request at this time, please try again later.';
export const MSG_NOT_FOUND_PAGE_ERROR = 'We are unable to load this page, please make sure you have the correct address and try again.';
export const MSG_NOT_FOUND_ERROR = 'We could not find the requested resource, please check your input an try again.';
export const SELECT_LOCATION_OPTION_TEXT = 'select a location';
export const SELECT_CLIENT_OPTION_TEXT = 'select a client';

export const CARE_TYPES = [
	'Bathing/Showering',
	'Hair Care',
	'Dressing',
	'Lotion/Ointment',
	'Oral Hygiene/Dentures',
	'Meal Preparation',
	'Eating/Drinking',
	'Laundry',
	'Light Housekeeping',
	'Shopping',
	'Medication Reminders',
	'Reading/Writing',
	'Managing Finances',
	'Social/Leisure Activities',
	'Telephone/Communication Devices',
	'Securing Transportation',
	'Appointment Scheduling',
	'Caring for Personal Possessions',
	'Obtaining Seasonal Clothing',
	'Using Prosthetic Device',
	'Ambulating/Supervised Walks',
	'Range of Motion',
	'Supervising/Coaching/Cueing',
	'Toileting',
	'Bowel/Bladder Management',
	'Transfers',
	'Incontinence Care',
];

export const SERVICE_RATINGS = [
	'Friendliness/Courtesy of the Caregiver',
	'Your confidence in our services',
	'The respectfulness of our staff',
	'Getting help after hours',
	'Likelihood of recommending us to friends and family',
];

export const SERVICE_RATINGS_OPTIONS = [
	'Poor',
	'Fair',
	'Good',
	'Excellent',
];
